import React from 'react';
import { useResponsive } from '../../contexts/responsive';
import { useCustomerMyJobs } from '../../contexts/customerMyJobs';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const CustomerJobsColumnHeaders = () => {
  const { responsive: { width }, BREAKPOINTS } = useResponsive();
  const { handleColumnHeaderClick, sortBy, sortAscending } = useCustomerMyJobs();

  return (
    <div className="flex gap-4 py-2 px-6 bg-navy-bright/10 border-b border-navy-light justify-between">
      <div className='flex flex-row w-2/3 md:w-1/6 items-center cursor-pointer'
        onClick={() => handleColumnHeaderClick('jobName')}>
        <p className="mt-1 text-gray-blue text-sm whitespace-nowrap">Job Name</p>
        {sortBy === 'jobName' && (
          sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
        )}
      </div>
      {(width > BREAKPOINTS.md) && (
        <>
          <div className='flex flex-row w-1/3 items-center cursor-pointer' 
            onClick={() => handleColumnHeaderClick('address')}>
            <p className='text-gray-blue text-sm whitespace-nowrap'>Address</p>
            {sortBy === 'address' && (
              sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
            )}
          </div>
          <div className='flex flex-row w-1/6 items-center cursor-pointer' 
            onClick={() => handleColumnHeaderClick('requestedDate')}>
            <p className='text-gray-blue text-sm whitespace-nowrap'>Requested Date</p>
            {sortBy === 'requestedDate' && (
              sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
            )}
          </div>
          <div className='flex flex-row w-1/6 items-center cursor-pointer' 
            onClick={() => handleColumnHeaderClick('scheduledAt')}>
            <p className='text-gray-blue text-sm whitespace-nowrap'>Scheduled Date</p>
            {sortBy === 'scheduledAt' && (
              sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
            )}
          </div>
          <div className='flex flex-row w-1/12 items-center cursor-pointer'
            onClick={() => handleColumnHeaderClick('jobType')}>
            <p className='text-gray-blue text-sm whitespace-nowrap'>Service</p>
            {sortBy === 'jobType' && (
              sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
            )}
          </div>
        </>
      )}
      <div className='flex flex-row w-1/3 md:w-1/12 items-center cursor-pointer' 
        onClick={() => handleColumnHeaderClick('status')}>
        <p className='text-gray-blue text-sm whitespace-nowrap'>Status</p>
        {sortBy === 'status' && (
          sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
        )}
      </div>
    </div>
  );
}

export default CustomerJobsColumnHeaders;
