import React, { createContext, useContext, useEffect, useState } from 'react'
import cookies from 'browser-cookies'
import { useAuth } from './auth'
import Service from '../service'
import { useNavigate, useLocation } from 'react-router-dom';

const ForemanContext = createContext()

const DEFAULT_JOB = {
  scheduledDate: '',
  job: {
    jobName: '',
    address: '',
  },
  jobLog: {
    finishedJobPhotos: []
  }
}

const DEFAULT_SELECTED_FOREMAN = {
  id: null,
  crew: {
    calendarJobs: [],
    location: {
      crews: [

      ],
      drivers: [

      ]
    }
  }
}

const ForemanProvider = props => {
  const { me, logout } = useAuth()
  const [selectedForeman, setSelectedForeman] = useState(DEFAULT_SELECTED_FOREMAN)
  const [todaysJobs, setTodaysJobs] = useState([DEFAULT_JOB])
  const [locationCrewMembers, setLocationCrewMembers] = useState([])
  const [locationDrivers, setLocationDrivers] = useState([])
  const [selectedCalendarJob, setSelectedCalendarJob] = useState(DEFAULT_JOB)
  const [selectedCrewMembers, setSelectedCrewMembers] = useState([])
  const [selectedDrivers, setSelectedDrivers] = useState([])
  const [deletedLocationCrewMembers, setDeletedLocationCrewMembers] = useState([])
  const [deletedLocationDrivers, setDeletedLocationDrivers] = useState([])
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const [language, setLanguage] = useState('English')
  const [isEditing, setIsEditing] = useState(false)
  const { accessToken } = Service.getAuthTokens()
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [allPersonnel, setAllPersonnel] = useState([])
  const [personnelLocation, setPersonnelLocation] = useState(1) //This is the id of the location which is being actively used to select drivers and crewMembers from.  Initialized as 1, then set to foreman's crew location upon page load.
  const [loginLink, setLoginLink] = useState(null)
  const [locationDropdownOptions, setLocationDropdownOptions] = useState([])


  useEffect(() => {
    if (!accessToken && !pathname.includes('onboard')) {
      navigate('/login')
      logout()
    }
  }, [accessToken])

  const foremanIdCookie = Number(cookies.get('foremanId'))

  useEffect(() => {
    const filteredForemen = me.foreman.filter(f => f.deletedAt === null)
    let foremanObj
    if (me?.foreman?.length > 0) {
      //set the selectedForeman to either the one matching the cookie or the first one in the array
      foremanObj = filteredForemen.find((item) => (
        item.id === foremanIdCookie
      )) || filteredForemen[0]
      setSelectedForeman(foremanObj)
    }
    if (me?.foreman) {
      let dropdownOptions = []
      dropdownOptions = filteredForemen.map(item => ({
        label: item.crew.location.label,
        value: item.id
      }))
      dropdownOptions.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      setLocationDropdownOptions(dropdownOptions);
    }
    if (foremanObj?.id) {
      const newTodaysJobs = foremanObj.crew.calendarJobs.map((calendarJobCrew) => {
        return calendarJobCrew.calendarJob
      })
      if (newTodaysJobs.length) {
        setTodaysJobs(newTodaysJobs)
      } else { //this block will cause the UI to visually update to reflect the condition of no jobs for the day
        setTodaysJobs([DEFAULT_JOB])
      }

      const newLocationCrewMembers = foremanObj.crew.location.crews.flatMap((crew) =>
        crew?.crewMembers?.filter((member) => member?.deletedAt === null)
      )
      if (newLocationCrewMembers.length) {
        setLocationCrewMembers(newLocationCrewMembers)

        const newDeletedLocationCrewMembers = foremanObj.crew.location.crews.flatMap((crew) =>
          crew?.crewMembers?.filter((member) => member?.deletedAt !== null)
        )
        setDeletedLocationCrewMembers(newDeletedLocationCrewMembers);
      }

      const newLocationDrivers = foremanObj.crew.location.drivers
        .filter((driver) => driver?.deletedAt === null);
      if (newLocationDrivers.length) {
        setLocationDrivers(newLocationDrivers)
        const newDeletedLocationDrivers = foremanObj.crew.location.drivers
          .filter((driver) => driver?.deletedAt !== null);
        setDeletedLocationDrivers(newDeletedLocationDrivers);
      }

      setPersonnelLocation(foremanObj.crew.location.id)
      if (!loginLink) {
        async function fetchData() {
          const newLoginLink = await Service.getForemanLoginLink(me.email)
          if (newLoginLink) {
            setLoginLink(newLoginLink)
          }
        }
        fetchData()
      }
    }
  }, [me, foremanIdCookie])

  useEffect(() => {
    const getPersonnelArray = async () => {
      const locationsArray = await Service.getPersonnelForAllLocations()
      setAllPersonnel(locationsArray)
    }
    getPersonnelArray()
  }, [])

  const updateLocationPersonnel = (personnelLocation) => {
    setPersonnelLocation(personnelLocation)
    setLocationDrivers(allPersonnel.find((location) => location.id === personnelLocation).drivers)
    setLocationCrewMembers(allPersonnel.find((location) => location.id === personnelLocation).crews.map((crew) => {
      return crew.crewMembers.map((crewMember) => crewMember)
    }).flat())
  }

  return (
    <ForemanContext.Provider value={{
      showMobileNav, setShowMobileNav,
      isAnimatingOut, setIsAnimatingOut,
      language, setLanguage,
      todaysJobs, setTodaysJobs,
      selectedCalendarJob, setSelectedCalendarJob,
      locationCrewMembers, setLocationCrewMembers,
      selectedCrewMembers, setSelectedCrewMembers,
      locationDrivers, setLocationDrivers,
      selectedDrivers, setSelectedDrivers,
      loginLink, DEFAULT_JOB,
      allPersonnel, setAllPersonnel,
      personnelLocation, setPersonnelLocation,
      updateLocationPersonnel,
      locationDropdownOptions, setLocationDropdownOptions,
      selectedForeman, setSelectedForeman,
      isEditing, setIsEditing,
      deletedLocationCrewMembers, setDeletedLocationCrewMembers,
      deletedLocationDrivers, setDeletedLocationDrivers
    }}>
      {props.children}
    </ForemanContext.Provider>
  )
}

const useForeman = () => useContext(ForemanContext)
export { useForeman }
export default ForemanProvider
