import React from 'react'
import CalendarJobCardMobile from '../CalendarJobCardMobile'
import { useSchedulerCalendarMobile } from '../../contexts/schedulerCalendarMobile'

const TimeslotMobile = ({ scheduledDate, crewId, timeslotJob, shoot, color }) => {
  const { displayMode, dropActive, receiveJob } = useSchedulerCalendarMobile()

  const timeslotObj = {
    scheduledDate: scheduledDate,
    crewId: crewId,
    shift: shoot
  }

  const timeslotDisplay = () => {
    if (displayMode === 'short') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCardMobile job={timeslotJob} color={color} />
            :
            <div
              className={`flex justify-center items-center ${color} 
              ${dropActive ? 'h-[48px] border border-violet-700 border-solid border-2 rounded bg-violet-700 bg-opacity-20' : 'bg-opacity-30'}`}
              onClick={() => {if (dropActive) receiveJob(timeslotObj)}}
            />
          }
        </>
      )
    } else {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCardMobile job={timeslotJob} color={color} />
            :
            <div
              className={`flex flex-1 justify-center items-center ${color} border-dashed ${dropActive ? 'h-[44px] border border-violet-700 border-solid border-2 rounded bg-violet-700 bg-opacity-20' : 'bg-opacity-30'}`}
              onClick={() => {if (dropActive) receiveJob(timeslotObj)}}
            />
          }
        </>
      )
    } 
  }


  return (
    <>{timeslotDisplay()}</>
  )
}

export default TimeslotMobile