import { useSchedulerFiles } from "../../../contexts/SchedulerFiles";
import FilesBox from "../../../components/FilesBox";
import SchedulerLocationDropdown from "../../../components/SchedulerLocationDropdown";
import { useAuth } from "../../../contexts/auth";
import { useScheduler } from "../../../contexts/scheduler";
import { useResponsive } from "../../../contexts/responsive";
import cookies from 'browser-cookies';

const SchedulerFilesView = () => {
  const {
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    allItems,
    RESULTS_PER_PAGE,
    sortBy,
    setSortBy,
    sortAscending,
    setSortAscending,
    handleColumnHeaderClick,
    displayItems,
  } = useSchedulerFiles();
  const { isMobileView } = useResponsive();
  const { me } = useAuth();
  const { selectedScheduler, setSelectedScheduler, locationDropdownOptions } = useScheduler();

  return (
    <div className="w-full overflow-y-scroll bg-opacity-10 bg-navy-bright flex flex-col justify-start items-center">
      <div className={`bg-white rounded-2xl w-full md:w-5/6 xl:w-2/3 md:mr-5 my-6 flex flex-col items-center border border-navy-light `}>
        <div className="h-16 relative w-full flex flex-row justify-between items-center px-6  rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
          <h1 className="text-xl font-semibold text-dark-blue">Files</h1>
          {!isMobileView && me?.scheduler?.length > 1 &&
            <SchedulerLocationDropdown
              value={selectedScheduler?.location?.id}
              placeholder={'Schedule Location:'}
              options={locationDropdownOptions}
              onChange={(e) => {
                const selectedLocationId = parseInt(e.target.value);
                const matchingScheduler = me?.scheduler?.find((item => item.location.id === selectedLocationId));
                if (matchingScheduler) {
                  cookies.set('schedulerId', matchingScheduler.id.toString());
                  setSelectedScheduler(matchingScheduler);
                }
              }}
              role='scheduler'
            />
          }
        </div> 
        <FilesBox 
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allItems={allItems}
          RESULTS_PER_PAGE={RESULTS_PER_PAGE}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortAscending={sortAscending}
          setSortAscending={setSortAscending}
          handleColumnHeaderClick={handleColumnHeaderClick}
          displayItems={displayItems}
        />
    </div>
  </div>
  );
}

export default SchedulerFilesView;