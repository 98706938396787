import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth'
import { useForeman } from '../../contexts/foreman';
import { useResponsive } from '../../contexts/responsive';
import './index.scss'
import { MdOutlineClose } from 'react-icons/md';
import SchedulerLocationDropdown from '../SchedulerLocationDropdown';
import cookies from 'browser-cookies'
import { useModal } from '../../contexts/modal';
import AskIfContinueLocModal from '../AskIfContinueLocModal';

const ForemanMobileNav = () => {
  const { showMobileNav, setShowMobileNav, selectedForeman, setSelectedForeman, setSelectedCalendarJob, DEFAULT_JOB, locationDropdownOptions, isEditing, setIsEditing } = useForeman()
  const { modalMethods } = useModal()
  const { logout } = useAuth()
  const { responsive } = useResponsive();
  const { me } = useAuth();
  const [focus, setFocus] = useState('dailyjoblog');
  const [isAnimating, setIsAnimating] = useState(false);
  const [visible, setVisible] = useState(false);
  const drawerRef = useRef(null);


  const handleToggle = () => {
    setShowMobileNav(!showMobileNav)
  }

  useEffect(() => {
    if (showMobileNav) {
      setVisible(true);
      setIsAnimating(true);
    }
  }, [showMobileNav]);

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (!showMobileNav) {
        setVisible(false);
      }
      setIsAnimating(false);
    };

    const drawer = drawerRef.current;
    if (drawer) {
      drawer.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (drawer) {
        drawer.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [showMobileNav]);

  const handleLinkClick = (newFocus) => {
    setFocus(newFocus)
    setShowMobileNav(false);
  }

  const style = {
    width: responsive.width - 64,
    height: '100%',
    top: 0,
    position: 'fixed',
    zIndex: 10,
  };

  const animationClass = isAnimating ? (showMobileNav ? 'drawerSlideIn' : 'drawerSlideOut') : '';

  const handleOverlayClick = (e) => {
    setTimeout(() => {
      handleToggle();
    }, 300);
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {visible && isAnimating && (
        <div onClick={handleOverlayClick} className="z-10 fixed inset-0 flex justify-end lg:justify-end items-center">
          <div onClick={handleContentClick} ref={drawerRef} style={style} className={`z-10 border border-black flex flex-col lg:hidden text-white bg-dark-blue ${animationClass}`}>
            <div className='flex flex-row justify-between h-8 py-3 px-3'>
              <div />
              <MdOutlineClose onClick={handleToggle} className='cursor-pointer text-3xl' />
            </div>
            <div className='pt-3 pb-8 pl-6 pr-6 flex flex-col justify-between items-start'>
              <NavLink
                to='/foreman'
                onClick={() => { handleLinkClick('dailyJobLog') }}
                className='flex w-full items-center py-[15px]'
                style={{ display: 'grid', gridTemplateColumns: '37px auto' }}
                activeclassname='text-white'
                exact="true"
              >
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <div className='w-full flex justify-center items-center'>
                    <img
                      src={focus === 'dailyjoblog' ? '/sidebar/daily_job_log_icon.svg' : '/sidebar/daily_job_log_icon_disabled.svg'}
                      alt='daily job log'
                      style={{ widsth: '100%', maxWidth: '27px' }}
                    />
                  </div>
                </div>
                <p className={`text-xl pl-8 ${focus === 'dailyjoblog' ? 'text-white' : 'text-slate-400'}`}>Daily Job Log</p>
              </NavLink>
              <NavLink
                to='/foreman/account'
                onClick={() => { handleLinkClick('account') }}
                className='flex w-full items-center py-[15px]'
                style={{ display: 'grid', gridTemplateColumns: '37px auto' }}
                activeclassname='text-white'
                exact="true"
              >
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <div className='w-full flex justify-center items-center'>
                    <img
                      src={focus === 'account' ? '/sidebar/account_icon.svg' : '/sidebar/account_icon_disabled.svg'}
                      alt='account page'
                      style={{ widsth: '100%', maxWidth: '27px' }}
                    />
                  </div>
                </div>
                <p className={`text-xl pl-8 ${focus === 'account' ? 'text-white' : 'text-slate-400'}`}>Account</p>
              </NavLink>
            </div>
            {me?.foreman.length > 1
              ?
              <div className='pl-6 pb-8'>
                <p className='font-medium text-sm pb-1'>View jobs for:</p>
                <SchedulerLocationDropdown
                  value={selectedForeman?.id}
                  placeholder='Jobs for branch: '
                  options={locationDropdownOptions}
                  onChange={(e) => {
                    const newForemanId = parseInt(e.target.value)
                    const matchingForeman = me?.foreman?.find(item => item.id === newForemanId)
                    if (matchingForeman) {
                      if (isEditing) {
                        modalMethods.setContent(<AskIfContinueLocModal
                          newForeman={matchingForeman}
                          setSelectedForeman={setSelectedForeman}
                          setIsEditing={setIsEditing}
                          setSelectedCalendarJob={setSelectedCalendarJob}
                          DEFAULT_JOB={DEFAULT_JOB}
                        />)
                        modalMethods.open()
                      } else {
                        cookies.set('foremanId', matchingForeman.id.toString());
                        setSelectedForeman(matchingForeman) //necessary for changing the value of the selector
                        setSelectedCalendarJob(DEFAULT_JOB) //causes useEffect to run updating state in the context
                      }
                    }
                  }}
                />
              </div>
              :
              null
            }
            <div className={`flex flex-col h-full pl-6`}>
              <div className='pb-6'>
                <button
                  className='flex flex-row w-full items-center'
                  onClick={logout}
                >
                  <img src="/sidebar/logout_icon.svg" alt="Logout Icon" className='w-[27px] mr-8' />
                  <p className='text-xl text-white'>Sign out</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForemanMobileNav;
