import React from 'react'
import CalendarDay from '../CalendarDay'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import DailyReportModal from '../DailyReportModal'
import DailyReportDownloadModal from '../DailyReportDownloadModal'

const Calendar = () => {
  const { interval, displayMode, days, filteredDays, calendarJobs } = useSchedulerCalendar()

  const doDaysMatch = (jobDate, calDate) => {
    return (
      jobDate.getUTCFullYear() === calDate.getUTCFullYear()
      && jobDate.getUTCMonth() === calDate.getUTCMonth()
      && jobDate.getUTCDate() === calDate.getUTCDate()
    )
  }

  //function to render calendar days in 4 different versions, depending on choice of views.  Eventually may be 8 versions, with mobile views of each.
  const calendarDisplay = () => {
    if (interval === 'week' && displayMode === 'vertical') {
      return (
        <div className='grid grid-cols-3 gap-2 w-full h-full p-2 min-h-[672px] bg-navy-bright/10 text-lg text-black font-bold'>
          <CalendarDay
            day={days[1]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[1]))}
            key={`calDay${days[1].toUTCString()}`} />
          <CalendarDay
            day={days[2]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[2]))}
            key={`calDay${days[2].toUTCString()}`} />
          <CalendarDay
            day={days[3]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[3]))}
            key={`calDay${days[3].toUTCString()}`} />
          <CalendarDay
            day={days[4]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[4]))}
            key={`calDay${days[4].toUTCString()}`} />
          <CalendarDay
            day={days[5]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[5]))}
            key={`calDay${days[5].toUTCString()}`} />
          <CalendarDay
            day={days[6]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[6]))}
            key={`calDay${days[6].toUTCString()}`} />
            <DailyReportModal />
            <DailyReportDownloadModal />
        </div>
      )
    } else if (interval === 'week' && displayMode === 'horizontal') {
      return (
        <div className='flex gap-2 p-2 min-h-[672px] bg-navy-bright/10'>
          <CalendarDay
            day={days[1]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[1]))}
            key={`calDay${days[1].toUTCString()}`} />
          <CalendarDay
            day={days[2]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[2]))}
            key={`calDay${days[2].toUTCString()}`} />
          <CalendarDay
            day={days[3]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[3]))}
            key={`calDay${days[3].toUTCString()}`} />
          <CalendarDay
            day={days[4]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[4]))}
            key={`calDay${days[4].toUTCString()}`} />
          <CalendarDay
            day={days[5]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[5]))}
            key={`calDay${days[5].toUTCString()}`} />
          <CalendarDay
            day={days[6]}
            dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[6]))}
            key={`calDay${days[6].toUTCString()}`} />
            <DailyReportModal />
            <DailyReportDownloadModal />
        </div>
      )
    } else if (interval === 'month' && displayMode === 'vertical') {
      return (
        <div className='flex flex-col gap-2 h-full p-2 min-h-[672px] bg-navy-bright/10'>
          <div className='grid grid-cols-6 gap-2'>
            <div className='text-gray-blue text-sm'>Mon</div>
            <div className='text-gray-blue text-sm'>Tue</div>
            <div className='text-gray-blue text-sm'>Wed</div>
            <div className='text-gray-blue text-sm'>Thu</div>
            <div className='text-gray-blue text-sm'>Fri</div>
            <div className='text-gray-blue text-sm'>Sat</div>
          </div>
          <div className='grid grid-cols-6 gap-2 h-full'>
            {filteredDays.map((day, dayIdx) => (
              <CalendarDay
                day={day}
                dayIdx={dayIdx}
                dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), day))}
                key={`calendar-day-${dayIdx}`}
              />
            ))}
            <DailyReportModal />
            <DailyReportDownloadModal />
          </div>
        </div>
      )
    } else {
      return (
        <div className='flex flex-col gap-2 h-full p-2 min-h-[672px] bg-navy-bright/10'>
          <div className='grid grid-cols-6 gap-2'>
            <div className='text-gray-blue text-sm'>Mon</div>
            <div className='text-gray-blue text-sm'>Tue</div>
            <div className='text-gray-blue text-sm'>Wed</div>
            <div className='text-gray-blue text-sm'>Thu</div>
            <div className='text-gray-blue text-sm'>Fri</div>
            <div className='text-gray-blue text-sm'>Sat</div>
          </div>
          <div className='grid grid-cols-6 gap-2 h-full'>
            {filteredDays.map((day, dayIdx) => (
              <CalendarDay
                day={day}
                dayIdx={dayIdx}
                dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), day))}
                key={`calendar-day-${dayIdx}`}
              />
            ))}
            <DailyReportModal />
            <DailyReportDownloadModal />
          </div>
        </div>
      )
    }
  }

  return (
    <div id="calendarBody" className='w-full lg:w-3/4 overflow-auto'>
      {calendarDisplay()}
      <div className={`h-full w-full bg-opacity-60 border-dashed border-2 hidden`} id='dropZone'>
        <img src='/vectors/Plus_icon_indicator.svg' alt='place-holder' />
      </div>
      <div className={`h-full w-full bg-opacity-60 border-dashed border-2 hidden`} id='fDropZone'>
        <img src='/vectors/Plus_icon_indicator.svg' alt='place-holder' />
      </div>
    </div>
  )
}

export default Calendar
