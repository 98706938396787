import React, { useEffect, useState } from 'react'
import { useScheduler } from '../../contexts/scheduler'
import DropdownRadio from '../DropdownRadio'
import { useSchedulerCalendarMobile } from '../../contexts/schedulerCalendarMobile'
import JFJobCardMobile from '../JFJobCardMobile'
import JFJobCardFlashingMobile from '../JFJobCardFlashingMobile'
import service from '../../service'
import { useAuth } from '../../contexts/auth'

const JobFlashingsPaneMobileGunite = () => {
  const { me, setMe } = useAuth()
  const { selectedScheduler } = useScheduler()
  const { location: { jobs: allJobs, service: locationService } } = selectedScheduler
  const { flashingJobs, jobRef, dropActive, setDropActive, dropActiveFlashing, setDropActiveFlashing, draggedFrom, draggedTo } = useSchedulerCalendarMobile()
  const [JFDisplay, setJFDisplay] = useState('jobs')
  const [query, setQuery] = useState('')
  const [sortBy, setSortBy] = useState('submittedAt')
  const [visibleJobs, setVisibleJobs] = useState(allJobs)
  const [visibleFlashingJobs, setVisibleFlashingJobs] = useState(flashingJobs)
  const [open, setOpen] = useState(false)

  const sort = (jobs) => {
    if (sortBy === 'submit-date') {
      jobs.sort((a, b) => {
        if (b.submittedAt > a.submittedAt) { return 1; }
        if (b.submittedAt < a.submittedAt) { return -1; }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        return 0;
      })
    } else if (sortBy === 'request-date') {
      jobs.sort(function (a, b) {
        if (b.requestedDate > a.requestedDate) { return 1; }
        if (b.requestedDate < a.requestedDate) { return -1; }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        return 0;
      });
    } else if (sortBy === 'job-name') {
      jobs.sort(function (a, b) {
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        if (a.owner.customer.bizName.toLowerCase() > b.owner.customer.bizName.toLowerCase()) { return 1; }
        if (a.owner.customer.bizName.toLowerCase() < b.owner.customer.bizName.toLowerCase()) { return -1; }
        return 0;
      });
    } else if (sortBy === 'builder-name') {
      jobs.sort(function (a, b) {
        if (a.owner.customer.bizName.toLowerCase() > b.owner.customer.bizName.toLowerCase()) { return 1; }
        if (a.owner.customer.bizName.toLowerCase() < b.owner.customer.bizName.toLowerCase()) { return -1; }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        return 0;
      });
    }
    return jobs
  }

  useEffect(() => {
    const newFlashingJobs = flashingJobs
    const unScheduledJobs = newFlashingJobs.filter(job => !job.scheduledDate && !job.archivedAt)
    setVisibleFlashingJobs(unScheduledJobs)
  }, [flashingJobs, query, sortBy])

  useEffect(() => {
    if (Array.isArray(allJobs)) {
      let typeSpecificJobs = allJobs.filter(job => job.jobType === locationService.toLowerCase())
      let submittedJobs = typeSpecificJobs.filter(job => job.readyAt !== null && job.scheduledAt === null && job.archivedAt === null).sort((a, b) => new Date(b.requestedDate) - new Date(a.requestedDate))
      if (query !== '') {
        submittedJobs = submittedJobs.filter(job => {
          return (
            job.jobName.toLowerCase().includes(query.toLowerCase())
            || job.address.toLowerCase().includes(query.toLowerCase())
            || job.owner.customer.bizName.toLowerCase().includes(query.toLowerCase()))
        })
      }

      const sortedJobs = sort(submittedJobs)
      setVisibleJobs(sortedJobs)
    }
  }, [allJobs, query, sortBy])

  let startX = 0;
  let startY = 0;
  let distX = 0;
  let distY = 0;

  useEffect(() => {
    const surface = document.getElementById('jfTabs');

    const startTouch = (e) => {
      e.preventDefault()
      startX = e.changedTouches[0].pageX;
      startY = e.changedTouches[0].pageY;
    }

    const endTouch = (e) => {
      e.preventDefault()
      distX = e.changedTouches[0].pageX - startX;
      distY = e.changedTouches[0].pageY - startY;

      if (distY > 50) {
        //swipe down
        setOpen(false)
      } else if (distY < -50) {
        //swipe up
        setOpen(true);
      } else if (startX < e.view.outerWidth / 2) {
        document.getElementById('jobsButton').click()
      } else {
        document.getElementById('flashesButton').click()
      }
    }

    surface.addEventListener('touchstart', startTouch)
    surface.addEventListener('touchend', endTouch)

    return () => {
      surface.removeEventListener('touchstart', startTouch)
      surface.removeEventListener('touchend', endTouch)
    }
  }, [])

  /* 
  example jobRef.current: {
    calendarJocCrewId: 2778,
    calendarJobId: 2829,
    calendarJobType: "REGULAR",
    jobId: 1863
  } 
  */
  const handleDrop = async () => {
    if (jobRef.current.calendarJobType === 'REGULAR') {
      const calendarJobDetails = {
        ids: [jobRef.current.calendarJobId],
        jobId: jobRef.current.jobId
      }
      const { updatedJob } = await service.markCalendarJobDeletedFromCal(calendarJobDetails)
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                updatedJob,
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    } else {
      const { unscheduledFlashJob } = await service.unscheduleFlashJobFromCal(jobRef.current.calendarJobId)
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === unscheduledFlashJob.jobId)
      const selectedCalendarJobIndex = selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.findIndex(calendarJob => calendarJob.id === unscheduledFlashJob.id)
      
      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                {
                  ...selectedScheduler.location.jobs[selectedJobIndex],
                  calendarJobs: [
                    ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(0, selectedCalendarJobIndex),
                      unscheduledFlashJob,
                      ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(selectedCalendarJobIndex + 1),
                  ],
                },
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    }
    
    setDropActive(false)
    setDropActiveFlashing(false)
    draggedFrom.current = ''
    draggedTo.current = ''
  }

  return (
    <div
      className={`fixed flex flex-col w-full p-2 ${open ? 'top-[153px]' : 'top-[calc(100vh-45px)]'} bottom-0 bg-gray-light border border-top transition-all ease-in-out duration-350`}
    >
      <div className='flex w-full' id='jfTabs'>
        <button
          className={`w-full px-4 py-2 ${JFDisplay === 'jobs' ? 'bg-white rounded-t-lg border-solid border-x border-t border-black/30' : ''} `}
          onClick={() => setJFDisplay('jobs')}
          id='jobsButton'>
          <p className='text-sm font-semibold text-dark-blue'>Jobs</p>
        </button>
        <button
          className={`w-full px-4 py-2 ${JFDisplay === 'flashes' ? 'bg-white rounded-t-lg border-solid border-x border-t border-black/30' : ''} `}
          onClick={() => setJFDisplay('flashes')}
          id='flashesButton'>
          <p className='text-sm font-semibold text-dark-blue'>Flashes</p>
        </button>
      </div>
      <div className={`flex flex-col gap-2 h-full p-2 bg-white rounded-b-lg ${JFDisplay === 'jobs' ? "rounded-tr-lg" : "rounded-tl-lg"}`}> 
        <div className='relative flex gap-2 items-center pr-2'>
          <div className='flex w-full items-center border rounded'>
            <img src='/PlannerColumn/search_icon.svg'
              alt='search'
              className='py-[2px] px-2'
            />
            <input
              className='h-8 w-full rounded border-none'
              placeholder='Search'
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
              }}
            />
          </div>
          <DropdownRadio sortBy={sortBy} setSortBy={setSortBy} i={1} />
        </div>
        <div className='flex flex-col gap-2 overflow-y-auto'
          onClick={(e) => {
            e.preventDefault()
            if (dropActive || dropActiveFlashing) handleDrop()
          }}
        >
          {dropActive || dropActiveFlashing
            ?
            <div className={`w-full bg-opacity-60 border-dashed border-2 py-2`}>
              <img className='m-auto' src='/vectors/Plus_icon_indicator.svg' alt='place-holder' />
            </div>
            :
            null}

          {JFDisplay === 'jobs'
            ?
            visibleJobs.map((job, i) => {
              return (
                <JFJobCardMobile key={`job${i}`} job={job} setOpen={setOpen} />
              )
            })
            :
            visibleFlashingJobs.map((job, i) => {
              return (
                <JFJobCardFlashingMobile key={`jfjcf${i}`} job={job} setOpen={setOpen} />
              )
            })}

        </div>
      </div>
    </div>
  )
}

export default JobFlashingsPaneMobileGunite
