import SchedulerActivityLogProvider from "../../../contexts/SchedulerActivityLog";
import ActivityLogPage from "../../../components/ActivityLogPage";

const ActivityLogView = () => {
  
  return (
    <SchedulerActivityLogProvider>
      <ActivityLogPage />
    </SchedulerActivityLogProvider>
  );
}

export default ActivityLogView;
