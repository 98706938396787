import React, { useState, useEffect, useRef } from "react";
import { useSchedulerPlanner } from '../../contexts/schedulerPlanner'
import { useScheduler } from '../../contexts/scheduler'
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { FIFTY_STATES_OBJECT } from '../../constants';
import service from '../../service'

function JobCard({ job, tags }) {
  const [flagNew, setFlagNew] = useState(false)
  const { isDragging, handleDragStart, handleDragEnd } = useSchedulerPlanner()
  const { setShowJobDetailsModal } = useJobDetailsModal()
  const { setSelectedJobId, selectedScheduler } = useScheduler()
  const jobCardRef = useRef()

  const getActualYards = () => {
    // Displays actual yards shot if job logs exist (gunite only)
    let total = 0
    if (job?.calendarJobs?.length > 0) {
      job.calendarJobs.forEach((calJob) => {
        total = total + (Number(calJob.jobLog?.yards) || 0)
      })
    }

    return total
  }

  const totalYards = getActualYards()

  useEffect(() => {
    setFlagNew(job.jobType === 'plaster' ? job.estimatedIA <= 0 : job.estimatedYards <= 0)
  }, [job.estimatedIA, job.estimatedYards])

  useEffect(() => {
    const jobCard = jobCardRef.current
    jobCard.addEventListener('dragstart', handleDragStart)

    jobCard.addEventListener('dragend', handleDragEnd)
    return () => {
        jobCard.removeEventListener('dragstart', handleDragStart)

        jobCard.removeEventListener('dragend', handleDragEnd)
    }
  }, [selectedScheduler])

  return (
    <div
      ref={jobCardRef}
      className={`draggable flex min-h-[111px] w-full rounded-[8px]  bg-subtle-grey text-dark-blue border shadow-md font-roboto text-[14px] overflow-hidden ${isDragging ? '' : 'group hover:bg-card-bg-hover'}`}
      draggable='true'
      data-job={JSON.stringify(job)}
      onClick={async (e) => {
        if (job.openedAt === null) {
          service.updateDate(job.id, 'openedAt')
        }
        setSelectedJobId(job.id)
        setShowJobDetailsModal(true)
      }}
    >

      <div className={`w-[4px] flex-none ${flagNew ? 'bg-red-600' : 'bg-transparent'}`}>
      </div>

      <div className='flex flex-col w-[calc(100%-4px)] h-full p-2'>
        <div className='flex justify-between'>
          <p className='font-[800] w-8/12 truncate'>
            {job.jobName?.toUpperCase()}
          </p>
          <p className='text-gray-blue'>
            {job.calendarJobs.length ? (
              `S ${new Date(job.calendarJobs[0].scheduledDate).getUTCMonth() + 1}.${new Date(job.calendarJobs[0].scheduledDate).getUTCDate()}.${new Date(job.calendarJobs[0].scheduledDate).getUTCFullYear()}`
            ) : (
              `R ${new Date(job.requestedDate).getUTCMonth() + 1}.${new Date(job.requestedDate).getUTCDate()}.${new Date(job.requestedDate).getUTCFullYear()}`
            )}
          </p>
        </div>

        <div className='flex flex-col'>
          <p className='font-[500] truncate'>
            {job.address && job.city && job.state && job.zipCode ? `${job.address}, ${job.city}, ${FIFTY_STATES_OBJECT[job.state]} ${job.zipCode}` : 'Address *'}
          </p>
          <p className='text-gray-blue leading-none truncate'>
            {job.owner?.customer?.bizName ? job.owner.customer.bizName : 'Pool Builder *'}
          </p>
        </div>

        <div className='flex flex-wrap justify-between text-[12px] gap-x-2 pt-1'>

          <p className='flex bg-navy-light items-center group-hover:bg-card-accent-hover rounded-md px-2 py-1 font-[600] my-1'>
            {job.jobType === 'plaster' && job.estimatedIA > 0 ? `${job.estimatedIA} IA` : ''}
            {job.jobType !== 'plaster' && job.estimatedYards > 0 ? `${totalYards || job.estimatedYards} Yards` : ''}
            {job.estimatedYards <= 0 && job.estimatedIA <= 0 ? 'Estimate Required *' : ''}
          </p>

          <div className='tags w-fit  flex justify-end overflow-hidden'>
            {tags.map((tag, i) => (
                <p
                  key={`tag${i}`}
                  className='flex items-center bg-navy-light group-hover:bg-card-accent-hover rounded-md px-2 py-1 font-[600] my-1 mx-0.5'
                  title={tag.title}
                >
                  {tag.letters}
                </p>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default JobCard;
