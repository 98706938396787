import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SchedulerProvider from "../../../contexts/scheduler.js";
import SchedulerJobsListContextProvider from '../../../contexts/schedulerJobsList.js'
import JobDetailsModalContextProvider from '../../../contexts/jobDetailsModal.js'
import SchedulerFilesProvider from '../../../contexts/SchedulerFiles.js';
import CalendarView from '../CalendarView/index.js'
import PlannerView from '../PlannerView/index.js'
import SchedulerJobsListView from '../SchedulerJobsListView/index.js'
import ActivityLogView from '../ActivityLogView/index.js'
import SchedulerTopBar from '../../../components/SchedulerTopBar'
import MobileNav from '../../../components/MobileNav'
import Sidebar from '../../../components/Sidebar/index.js'
import FilesView from '../FilesView/index.js'
import AccountPage from '../../../components/AccountPage/index.js';
import CustomersView from '../CustomersView/index.js';
import DailyJobLog from '../DailyJobLogView/index.js';
import SchedulerOnboard from '../SchedulerOnboard';

const SchedulerLayout = () => {
  return (
    <div className="flex flex-col lg:flex-row h-screen w-screen min-h-[720px] font-roboto">
      <SchedulerProvider>
        <JobDetailsModalContextProvider>
          <SchedulerJobsListContextProvider>
            <SchedulerFilesProvider>
              <Sidebar role={"scheduler"} />
              <SchedulerTopBar />
              <MobileNav role={"scheduler"} />
              <Routes>
                <Route exact={true} path='/onboard' element={<SchedulerOnboard />} />
                <Route exact={true} path='/calendar' element={<CalendarView />} />
                <Route exact={true} path='/planner' element={<PlannerView />} />
                <Route exact={true} path='/jobsList' element={<SchedulerJobsListView />} />
                <Route exact={true} path='/activityLog' element={<ActivityLogView />} />
                <Route exact={true} path='/files' element={<FilesView />} />
                <Route exact={true} path='/account' element={<AccountPage />} />
                <Route exact={true} path='/customers' element={<CustomersView />} />
                <Route exact={true} path='/dailyJobLog' element={<DailyJobLog />} />
              </Routes>
            </SchedulerFilesProvider>
          </SchedulerJobsListContextProvider>
        </JobDetailsModalContextProvider>
      </SchedulerProvider>
    </div>
  )
}

export default SchedulerLayout
