import React, { createContext, useContext, useState, useEffect } from 'react'
import { DateTime } from 'luxon';
import { useScheduler } from './scheduler'

const SchedulerActivityLog = createContext()

const SchedulerActivityLogProvider = props => {

  const RESULTS_PER_PAGE = 15;

  const [displayItems, setDisplayItems] = useState([]);
  const [ allItems, setAllItems ] = useState([])
  const { selectedScheduler } = useScheduler();
  const [selectedPeople, setSelectedPeople] = useState([])
  const [peopleOptions, setPeopleOptions] = useState([])
  const [selectedActions, setSelectedActions] = useState([])
  const [actionOptions, setActionOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const DEFAULT_SELECTED_DAYS = {
    day1: 0,
    month1: null,
    year1: 0,
    day2: 0,
    month2: null,
    year2: 0,
  }
  const [selectedDays, setSelectedDays] = useState(DEFAULT_SELECTED_DAYS)

  const labelForAction = (activityType) => {
    switch (activityType) {
      case "intake":
        return "intake form submitted"
      case "backlog":
        return "job moved to backlog"
      case "ready":
        return "job marked as ready for calendar"
      case "scheduled":
        return "job scheduled"
      case "rescheduled":
        return "rescheduled"
      case "flashingScheduled":
        return "flashing scheduled"
      case "guniteCompleted":
        return "gunite completed"
      case "plasterCompleted":
        return "plaster completed"
      case "completed":
        return "job marked as completed"
      case "archive":
        return "archived"
      case "poolPlanUploaded":
        return "pool plan uploaded"
      case "assignedCrew":
        return "assigned crew"
      case "assigned drivers":
        return "job assigned"
      case "jobDetailsEdited":
        return "job details edited"
      case "jobTransferred":
        return "job transferred"
      case "dailyJobLogSubmitted":
        return "daily job log submitted"
      case "costBreakdownSubmitted":
        return "cost breakdown submitted"
      default:
        return activityType
    }
  }
  
  const locationJobs = selectedScheduler?.location?.jobs || []

  useEffect(() => {
    const activityLogs = [];
  
    for (const job of locationJobs) {
      for (const activityLog of job.activityLogs) {
        activityLogs.push({
          ...activityLog,
          jobName: job.jobName,
        }); 
      }
    }
  
    const sorted = activityLogs.sort((a, b) => {
      const dateA = new Date(a.activityDateTime);
      const dateB = new Date(b.activityDateTime);
      return dateB - dateA;
    });

    const peopleFiltered = selectedPeople.length > 0 ? sorted.filter((log) => selectedPeople.includes(log.performedBy.name)) : sorted

    const actionsFiltered = selectedActions.length > 0 ? peopleFiltered.filter((log) => selectedActions.includes(log.activityType)) : peopleFiltered

    const searchFiltered = searchTerm.length > 0 ? searchFilter(actionsFiltered) : actionsFiltered

    const selectedDates = 
    (selectedDays.day1 == 0 && selectedDays.day2 == 0) ?
    [] : getDatesInRange(selectedDays)

    const daysFiltered = selectedDates.length > 0 ? searchFiltered.filter(log => {
      const logISO = DateTime.fromJSDate(new Date(log.activityDateTime)).toISODate();
      return selectedDates.some(d => {
        const dayISO = d
        
        return dayISO === logISO;
      });
    }) : searchFiltered;

    setAllItems(daysFiltered)


    const people = [...new Set(sorted.map((log) => log.performedBy.name))];
    const peopleOptions = people.map(name => ({label: name, id: name}))
    setPeopleOptions(peopleOptions)

    const actions = [...new Set(sorted.map((log) => log.activityType))];
    const actionOptions = actions.map(name => ({label: name, id: name}))
    setActionOptions(actionOptions)

  }, [locationJobs, selectedPeople, selectedActions, selectedDays, searchTerm, currentPage]);
  
  useEffect(() => {
    const paginatedJobs = paginate(currentPage, allItems); 
    setDisplayItems(paginatedJobs);
}, [currentPage, allItems])

useEffect(() => {
  if (allItems.length > 0 && currentPage > Math.ceil(allItems.length / RESULTS_PER_PAGE)) {
      setCurrentPage(Math.ceil(allItems.length / RESULTS_PER_PAGE));  // Adjust page to the last possible page with items
  } else if (allItems.length === 0) {
      setCurrentPage(1);  // Reset to first page if no items exist
  }
}, [allItems]);  // Adjust currentPage based on items count

  const paginate = (currentPage, items) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = (startIndex + RESULTS_PER_PAGE);
    return items.slice(startIndex, Math.min(endIndex, items.length));
  }

  const searchFilter = (logs) => {
    return logs.filter(log => {
      const jobNameMatches = log.jobName && log.jobName.toLowerCase().includes(searchTerm);
      const activityTypeMatches = labelForAction(log.activityType) && labelForAction(log.activityType).toLowerCase().includes(searchTerm);
      const performedByMatches = log.performedBy && log.performedBy.name && log.performedBy.name.toLowerCase().includes(searchTerm);

      return jobNameMatches || activityTypeMatches || performedByMatches;
    })
  }

  const getDatesInRange = (selectedDays) => {
    const { day1, month1, year1, day2, month2, year2 } = selectedDays;

    //if only one day is selected
    if (day1 == 0) {
      return [
        new Date(year2, month2, day2).toISOString().split('T')[0]
      ]
    } else if (day2 == 0) {
      return [
        new Date(year1, month1, day1).toISOString().split('T')[0]
      ]
    }
  
    const startDate = new Date(year1, month1, day1);
    const endDate = new Date(year2, month2, day2);
  
    const dateArray = [];
    let currentDate = new Date(startDate);
  
    while (currentDate <= endDate) {
      dateArray.push(currentDate.toISOString().split('T')[0]);  
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dateArray;
  };

  return (
    <SchedulerActivityLog.Provider value={{
      displayItems,
      allItems,
      selectedPeople,
      setSelectedPeople,
      peopleOptions,
      setPeopleOptions,
      selectedActions,
      setSelectedActions,
      actionOptions,
      setActionOptions,
      searchTerm,
      setSearchTerm,
      selectedDays,
      setSelectedDays,
      labelForAction,
      currentPage,
      setCurrentPage,
      RESULTS_PER_PAGE,
    }}>
      {props.children}
    </SchedulerActivityLog.Provider>
  )
}

const useSchedulerActivityLog = () => useContext(SchedulerActivityLog)

export { useSchedulerActivityLog }
export default SchedulerActivityLogProvider
