import React from 'react'
import CustomersList from '../../../components/CustomersList';
import { useAuth } from '../../../contexts/auth';
import { useScheduler } from '../../../contexts/scheduler';
import { useResponsive } from '../../../contexts/responsive';
import SchedulerLocationDropdown from '../../../components/SchedulerLocationDropdown';
import cookies from 'browser-cookies';
import JobDetails from '../../../components/JobDetails';

const CustomersView = () => {
  const { me } = useAuth();
  const { selectedScheduler, setSelectedScheduler, locationDropdownOptions } = useScheduler();
  const { isMobileView } = useResponsive();

  return (
    <div className='w-full h-full'>
      {!isMobileView && me?.scheduler?.length > 1 &&
        <div className='flex justify-end w-full h-fit p-2 border-b-2 border-navy-light bg-navy-bright bg-opacity-10'>
          <SchedulerLocationDropdown
            value={selectedScheduler?.location?.id}
            placeholder={'Schedule Location:'}
            options={locationDropdownOptions}
            onChange={(e) => {
              const selectedLocationId = parseInt(e.target.value);
              const matchingScheduler = me?.scheduler?.find((item => item.location.id === selectedLocationId));
              if (matchingScheduler) {
                cookies.set('schedulerId', matchingScheduler.id.toString());
                setSelectedScheduler(matchingScheduler);
              }
            }}
            role='scheduler'
          />
        </div>
      }

      <div className='flex justify-center h-full w-full mt-6 px-3 sm:px-0'>
        <CustomersList />

        <JobDetails />
      </div>
    </div>
  );
}

export default CustomersView;
