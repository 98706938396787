import { useEffect } from 'react';
import './index.scss';
import { useScheduler } from '../../contexts/scheduler';
import { useJobDetailsModal } from '../../contexts/jobDetailsModal';
import { useResponsive } from '../../contexts/responsive';
import JobDetailsHeaderBar from '../JobDetailsHeaderBar';
import JobDetailsFooterButtons from '../JobDetailsFooterButtons';
import JobDetailsBody from '../JobDetailsBody';
import MiniCalendarModal from '../MiniCalendarModal';

const JobDetails = () => {
  const {
    showJobDetailsModal, 
    handleCloseJobDetailsModal,
    isAnimatingOut, setIsAnimatingOut,
    selectedJobDetailsTab, setSelectedJobDetailsTab,
    nestedModalOpen, isCostBreakdownChange, setShowCostBreakdownWarning
  } = useJobDetailsModal();

  const { selectedJobId } = useScheduler();

  const { responsive: { height }} = useResponsive();

  const handleModalContentClick = (e) => {
    if (nestedModalOpen) { return; }
    e.stopPropagation();
  };

  useEffect(() => {
    if (!showJobDetailsModal && isAnimatingOut) {
      setIsAnimatingOut(false);
    }
  }, [showJobDetailsModal, isAnimatingOut]);

  const shouldDisplayModal = showJobDetailsModal || isAnimatingOut;

  return (
    <>
      {shouldDisplayModal && (
        <div onMouseDown={() => {
          if (selectedJobDetailsTab === 'Cost Breakdown' && isCostBreakdownChange.current) {
            setShowCostBreakdownWarning({ show: true, continueFunction: () => { handleCloseJobDetailsModal() } })
            return
          }
          handleCloseJobDetailsModal()
        }} className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center lg:justify-end items-center">

          <div className={`flex z-50 fixed h-full w-full items-center lg:justify-end py-5 ${isAnimatingOut ? 'modal-exit' : 'modal-enter'}`}>
            <MiniCalendarModal />
            <div
              onMouseDown={handleModalContentClick}
              onClick={handleModalContentClick}
              style={{ height: height - 40 }}
              className={`bg-white rounded-2xl overflow-hidden max-h-full w-full md:w-5/6 lg:w-2/3 xl:w-1/2 md:mr-5 flex flex-col border border-navy-light `}
            >
              <JobDetailsHeaderBar
                handleCloseJobDetailsModal={handleCloseJobDetailsModal}
                selectedJob={selectedJobId}
              />
              <JobDetailsBody
                selectedContent={selectedJobDetailsTab}
                setSelectedContent={setSelectedJobDetailsTab}
              />
              <JobDetailsFooterButtons />
            </div>
          </div>

        </div>
      )}
    </>
  );
};

export default JobDetails;
