import React from 'react'
import CalendarJobCardFlashMobile from '../CalendarJobCardFlashMobile'
import { useSchedulerCalendarMobile } from '../../contexts/schedulerCalendarMobile'

const TimeslotFlashingMobile = ({ scheduledDate, crewId, ftimeslotJobs, fshift, color }) => {
  const { displayMode, receiveJobFlashing, dropActiveFlashing } = useSchedulerCalendarMobile()

  const timeslotObj = {
    scheduledDate: scheduledDate,
    crewId: crewId,
    fshift: fshift,
  }

  const fTimeslotDisplay = () => {
    if (displayMode === 'short') {
      return (
        <div
          className={`${dropActiveFlashing && ftimeslotJobs.length < 1 ? 'border border-violet-700 border-solid border-2 rounded bg-violet-700 bg-opacity-20 basis-8' : 'bg-opacity-30'} ${color}`}
          onClick={() => {if (dropActiveFlashing && ftimeslotJobs.length < 1) receiveJobFlashing(timeslotObj)}}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlashMobile job={flashJob} key={`fj-${timeslotIdx}`} color={color} />
          ))}
        </div>
      )
    } else {
      return (
        <div
          className={`${dropActiveFlashing && ftimeslotJobs.length < 1 ? 'border border-violet-700 border-solid border-2 rounded bg-violet-700 bg-opacity-20 h-12' : 'bg-opacity-30'} ${color}`}
          onClick={() => {if (dropActiveFlashing && ftimeslotJobs.length < 1) receiveJobFlashing(timeslotObj)}}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlashMobile job={flashJob} key={`fj-${timeslotIdx}`} color={color} />
          ))}
        </div>
      )
    } 
  } 

  return (
    <>{fTimeslotDisplay()}</>
  )
}

export default TimeslotFlashingMobile