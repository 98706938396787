import React from 'react'
import CalendarDayMobile from '../CalendarDayMobile'
import { useSchedulerCalendarMobile } from '../../contexts/schedulerCalendarMobile'

const CalendarMobile = () => {
  const { days, calendarJobs } = useSchedulerCalendarMobile()

  window.addEventListener('contextmenu', function (e) {
    e.preventDefault();
  }, true); //disables the annoying af right-click context menu that appears by default in the mobile development view when you long-press

  const doDaysMatch = (jobDate, calDate) => {
    return (
      jobDate.getUTCFullYear() === calDate.getUTCFullYear()
      && jobDate.getUTCMonth() === calDate.getUTCMonth()
      && jobDate.getUTCDate() === calDate.getUTCDate()
    )
  }

  return (
    <div id="calendarBody" className='w-full overflow-auto'>
      <div className='w-full h-[calc(100%-45px)] p-2 mb-[40px] bg-navy-bright/10 text-lg text-black font-bold overflow-y'>
        <CalendarDayMobile
          day={days[1]}
          dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[1]))} />
        <CalendarDayMobile
          day={days[2]}
          dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[2]))} />
        <CalendarDayMobile
          day={days[3]}
          dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[3]))} />
        <CalendarDayMobile
          day={days[4]}
          dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[4]))} />
        <CalendarDayMobile
          day={days[5]}
          dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[5]))} />
        <CalendarDayMobile
          day={days[6]}
          dayJobs={calendarJobs.filter((calendarJob) => doDaysMatch(new Date(calendarJob.scheduledDate), days[6]))} />
      </div>

    </div>
  )
}

export default CalendarMobile
