import React from 'react'
import JobFlashingsPane from '../../../components/CalendarJobFlashingsPane'
import CalendarDisplaySettingsBar from '../../../components/CalendarDisplaySettingsBar'
import Calendar from '../../../components/Calendar'
import SchedulerCalendarContextProvider from '../../../contexts/schedulerCalendar'
import JobDetails from '../../../components/JobDetails'
import CalendarMobileDisplaySettingsBar from '../../../components/CalendarMobileDisplaySettingsBar'
import CalendarMobile from '../../../components/CalendarMobile'
import JobFlashingsPaneMobile from '../../../components/CalendarMobileJobFlashingsPaneGunite'
import SchedulerCalendarMobileContextProvider from '../../../contexts/schedulerCalendarMobile'
import { useResponsive } from '../../../contexts/responsive'
import { useScheduler } from '../../../contexts/scheduler'
import JobFlashingsPaneMobilePlaster from '../../../components/CalendarMobileJobFlashingsPanePlaster'

const CalendarView = () => {
  const { isMobileView } = useResponsive();
  const { selectedScheduler } = useScheduler()

  //this view is split into two different versions, a mobile version (top) and a desktop version (bottom).
  return (
    <div className='flex flex-col w-full'>
      {isMobileView
        ?
        <SchedulerCalendarMobileContextProvider>
          <CalendarMobileDisplaySettingsBar />
          <div className='flex flex-col'>
            <CalendarMobile />
            {selectedScheduler.location.service === 'GUNITE'
              ?
              <JobFlashingsPaneMobile />
              :
              <JobFlashingsPaneMobilePlaster />
            }
          </div>
          <JobDetails />
        </SchedulerCalendarMobileContextProvider>
        :
        <SchedulerCalendarContextProvider>
          <CalendarDisplaySettingsBar />
          <div className='flex h-[calc(100vh-48px)]'>
            <JobFlashingsPane />
            <Calendar />
          </div>
          <JobDetails />
        </SchedulerCalendarContextProvider>
      }
    </div>
  )
}

export default CalendarView
