import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import {
  startOfToday,
  startOfWeek,
  eachDayOfInterval,
  endOfWeek,
  addDays,
  addMonths,
} from 'date-fns'
import { useScheduler } from "./scheduler";
import service from "../service";
import { useAuth } from "./auth";

const SchedulerCalendarMobileContext = createContext()

const SchedulerCalendarMobileContextProvider = props => {
  const { me, setMe } = useAuth()
  const [displayMode, setDisplayMode] = useState('short')
  const [calendarJobs, setCalendarJobs] = useState([])
  const [flashingJobs, setFlashingJobs] = useState([])
  const { selectedScheduler } = useScheduler()
  const { location: { jobs: allJobs } } = selectedScheduler
  const newCalendarJobs = useRef([])
  const newFlashingJobs = useRef([])
  const [dropActive, setDropActive] = useState(false)
  const [dropActiveFlashing, setDropActiveFlashing] = useState(false)
  const jobRef = useRef({})
  const draggedFrom = useRef('')
  const draggedTo = useRef('')

  const moveJob = (jobObj) => {
    if (!dropActive && !dropActiveFlashing) {
      setDropActive(true)
      jobRef.current = jobObj
    }
  }

  const moveJobFlashing = (jobObj) => {
    if (!dropActive && !dropActiveFlashing) {
      setDropActiveFlashing(true)
      jobRef.current = jobObj
    }
  }

  //only covers 2 cases, in contrast to desktop view.  Covers cases of moving job card from one timeslot to another, or moving from JF pane to calendar
  const receiveJob = async (timeslotObj) => {
    if (draggedFrom.current !== 'jobSidePane' && draggedTo.current !== 'jobSidePane') {
      //dragging from one shift to another on the calendar
      setDropActive(false)
      const calendarJobDetails = {
        calendarJobCrewId: jobRef.current.calendarJobCrewId,
        calendarJobId: jobRef.current.calendarJobId,
        newScheduledDate: timeslotObj.scheduledDate,
        shift: timeslotObj.shift,
        newCrewId: timeslotObj.crewId
      }
      const { updatedCalendarJob } = await service.updateCalendarJob(calendarJobDetails)
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedCalendarJob.jobId)
      const selectedCalendarJobIndex = selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.findIndex(calendarJob => calendarJob.id === updatedCalendarJob.id)

      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                {
                  ...selectedScheduler.location.jobs[selectedJobIndex],
                  calendarJobs: [
                    ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(0, selectedCalendarJobIndex),
                    updatedCalendarJob,
                    ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(selectedCalendarJobIndex + 1),
                  ]
                },
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    } else if (draggedFrom.current === 'jobSidePane') {
      //dragging from job pane onto the calendar
      const calendarJobDetails = {
        jobId: jobRef.current.jobId,
        crewId: timeslotObj.crewId,
        scheduledDate: timeslotObj.scheduledDate,
        shift: timeslotObj.shift,
        updateDate: true,
      }

      const { newCalendarJob } = await service.createCalendarJobFromCal(calendarJobDetails)
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === newCalendarJob.jobId)
      
      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                {
                  ...selectedScheduler.location.jobs[selectedJobIndex],
                  calendarJobs: [
                    newCalendarJob
                  ],
                  //Put in a simple truthy value for scheduledAt.  The important thing is that the value is not null.  If scheduledAt === null, it will remain in the job-flashes pane
                  scheduledAt: true
                },
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    }

    setDropActive(false)
    draggedFrom.current = ''
    draggedTo.current = ''
    jobRef.current = {}
  }

  const receiveJobFlashing = async (timeslotObj) => {
    if (draggedFrom.current !== 'jobSidePane' && draggedTo.current !== 'jobSidePane') {
      //job moved around on calendar, update the calendarJob
      const calendarJobDetails = {
        //info to update calendarJob
        calendarJobId: jobRef.current.calendarJobId,
        newScheduledDate: timeslotObj.scheduledDate,
        fshift: timeslotObj.fshift,

        //info to update calendarJobCrew
        calendarJobCrewId: jobRef.current.calendarJobCrewId,
        newCrewId: timeslotObj.crewId
      }
      const { updatedFlashJob } = await service.rescheduleFlashJobOnCal(calendarJobDetails)
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedFlashJob.jobId)
      const selectedCalendarJobIndex = selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.findIndex(calendarJob => calendarJob.id === updatedFlashJob.id)
      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                {
                  ...selectedScheduler.location.jobs[selectedJobIndex],
                  calendarJobs: [
                    ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(0, selectedCalendarJobIndex),
                      updatedFlashJob,
                      ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(selectedCalendarJobIndex + 1),
                  ],
                },
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    } else if (draggedFrom.current === 'jobSidePane') {
      // job dragged from sidePane to calendar, create calendarJob
      const calendarJobDetails = {
        calendarJobId: jobRef.current.calendarJobId,
        crewId: timeslotObj.crewId,
        scheduledDate: timeslotObj.scheduledDate,
        fshift: timeslotObj.fshift
      }

      const { updatedFlashJob } = await service.scheduleFlashJobOnCal(calendarJobDetails)
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedFlashJob.jobId)
      const selectedCalendarJobIndex = selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.findIndex(calendarJob => calendarJob.id === updatedFlashJob.id)
      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                {
                  ...selectedScheduler.location.jobs[selectedJobIndex],
                  calendarJobs: [
                    ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(0, selectedCalendarJobIndex),
                      updatedFlashJob,
                      ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(selectedCalendarJobIndex + 1),
                  ],
                },
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    }

    setDropActiveFlashing(false)
    draggedFrom.current = ''
    draggedTo.current = ''
    jobRef.current = {}
  }

  //setting calendarJobs and flash jobs
  useEffect(() => {
    newCalendarJobs.current = []
    newFlashingJobs.current = []
    allJobs.forEach((job) => {
      if (job.calendarJobs[0] && !job.archivedAt) {
        //add all the calJobs for a given job
        newCalendarJobs.current.push(...job.calendarJobs)
      }
    })
    setCalendarJobs(newCalendarJobs.current)
    newFlashingJobs.current = newCalendarJobs.current.filter((job) => job.calendarJobType === 'FLASHING')
    setFlashingJobs(newFlashingJobs.current)
  }, [allJobs])

  //calendar variables and logic below:
  const today = startOfToday() //date object
  const [currentDay, setCurrentDay] = useState(today) //date object


  const setDays = () => {
    return eachDayOfInterval({
      start: startOfWeek(currentDay),
      end: endOfWeek(currentDay)
    })
  }

  let days = setDays() //array of date objects running through the current week or the current month

  const filteredDays = days.filter((day) => {
    return day.getDay() !== 0
  }) //filteredDays is an array of days without Sundays, used for the monthly views.

  const previousWeek = () => {
    setCurrentDay(addDays(currentDay, -7))
  }

  const nextWeek = () => {
    setCurrentDay(addDays(currentDay, 7))
  }

  const previousMonth = () => {
    setCurrentDay(addMonths(currentDay, -1))
  }

  const nextMonth = () => {
    setCurrentDay(addMonths(currentDay, 1))
  }



  return (
    <SchedulerCalendarMobileContext.Provider value={{
      displayMode, setDisplayMode,
      today, currentDay, setCurrentDay,
      days, filteredDays,
      previousWeek, nextWeek,
      previousMonth, nextMonth,
      calendarJobs, flashingJobs,
      dropActive, setDropActive,
      dropActiveFlashing, setDropActiveFlashing,
      moveJob, moveJobFlashing,
      receiveJob, draggedFrom,
      newCalendarJobs, newFlashingJobs,
      draggedTo, jobRef,
      receiveJobFlashing
    }}>
      {props.children}
    </SchedulerCalendarMobileContext.Provider>
  )
}

const useSchedulerCalendarMobile = () => useContext(SchedulerCalendarMobileContext)
export { useSchedulerCalendarMobile }
export default SchedulerCalendarMobileContextProvider
