import React from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useScheduler } from '../../contexts/scheduler';
import UploadToOneDriveButton from '../UploadToOneDriveButton'

const CostBreakdownPDFViewer = ({ PDFTemplate, selectedJob, isViewerOpen, setIsViewerOpen, invoiceOrBreakdown }) => {
  const { selectedScheduler } = useScheduler()

  return (
    <div className='flex justify-center items-center fixed w-screen h-screen top-0 left-0 bg-navy-light bg-opacity-60 z-10'>
      <div className="flex flex-col w-fit h-fit rounded-lg bg-all-f-white border border-navy-light p-6">
        <h2 className="text-lg font-bold text-dark-blue">{invoiceOrBreakdown.current === 'breakdown' ? 'Breakdown Preview' : 'Invoice Preview'}</h2>

        <div className='bg-[rgb(64,67,69)] p-1 rounded-md'>
          <PDFViewer className='h-[50vh] w-[420px]' showToolbar={false}>
            {PDFTemplate}
          </PDFViewer>
        </div>

        <div className="flex justify-center items-start gap-4 pt-4">

          <button
            onClick={() => {
              setIsViewerOpen(!isViewerOpen)
              invoiceOrBreakdown.current = 'breakdown'
            }}
            className="bg-white text-dark-blue rounded-md border border-dark-blue py-2 px-4 hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue active:border-gray-300"
          >
            Close
          </button>

          {invoiceOrBreakdown.current === 'breakdown'
            ?
            <>
              <PDFDownloadLink
                className="bg-white text-gunite-red border border-gunite-red rounded-md py-2 px-4 hover:text-white hover:bg-gunite-red active:bg-white active:text-gunite-red"
                fileName={`Breakdown_${selectedJob.calendarJobs[0].scheduledDate.substring(0,10)}_${selectedJob.jobName}_${selectedJob.id}`}
                document={PDFTemplate}
              >Download PDF</PDFDownloadLink>

              <UploadToOneDriveButton
                selectedScheduler={selectedScheduler}
                PDFTemplate={PDFTemplate}
                selectedJob={selectedJob}
                isBreakdown={true}
              />
            </>
            :
            <PDFDownloadLink
              className="bg-white text-gunite-red border border-gunite-red rounded-md py-2 px-4"
              fileName={`Invoice_${selectedJob.calendarJobs[0].scheduledDate.substring(0,10)}_${selectedJob.jobName}_${selectedJob.id}`}
              document={PDFTemplate}
            >Download PDF</PDFDownloadLink>
          }

        </div>
      </div>
    </div>
  )
};

export default CostBreakdownPDFViewer;
