import { useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useScheduler } from "../../contexts/scheduler";
import { formatDate } from "../../utils";

const DataRow = ({ label, value, isFirstChild, isLastChild }) => (
  <div className={`flex justify-between items-center py-1 ${isFirstChild ? 'rounded-tl-lg rounded-tr-lg' : ''} ${isLastChild ? 'rounded-bl-lg rounded-br-lg' : ''}`}>
    <span className="text-dark-blue py-1">{label}</span>
    <span className="text-dark-blue py-1">{value}</span>
  </div>
);

const DataSection = ({ title, sectionData }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  }

  return (
  <div className="mt-4 mb-6">
    <div className="flex justify-between mb-2 mx-2">
      <h3 className="text-md font-bold text-dark-blue">{title}</h3>
      {isExpanded ? <FaChevronUp onClick={handleToggleExpand} className="cursor-pointer text-dark-blue"/> : <FaChevronDown onClick={handleToggleExpand} className="cursor-pointer text-dark-blue"/>}
    </div>
    {isExpanded && (
      <div className="flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
        {Object.entries(sectionData).map(([label, value], index, array) => (
          <DataRow
            key={label}
            label={label}
            value={value}
            isFirstChild={index === 0}
            isLastChild={index === array.length - 1}
          />
        ))}
      </div>
    )}
  </div>
  )
};

  const JobDetailsDetailsTabReadable = () => {
    const { selectedJobId, selectedScheduler } = useScheduler();
    const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)
    const perimeterFtForDisplay = selectedJob.pools?.[0]?.perimeterFt ? selectedJob.pools?.[0]?.perimeterFt + ' ft ' : '';
    const perimeterInchesForDisplay = selectedJob.pools?.[0]?.perimeterInches ? selectedJob.pools?.[0]?.perimeterInches + ' inches' : '';
    const widthFtForDisplay = selectedJob.pools?.[0]?.widthFt ? selectedJob.pools?.[0]?.widthFt + ' ft ' : 'n/a';
    const widthInchesForDisplay = selectedJob.pools?.[0]?.widthInches ? selectedJob.pools?.[0]?.widthInches + ' inches' : '';
    const lengthFtForDisplay = selectedJob.pools?.[0]?.lengthFt ? selectedJob.pools?.[0]?.lengthFt + ' ft ' : 'n/a';
    const lengthInchesForDisplay = selectedJob.pools?.[0]?.lengthInches ? selectedJob.pools?.[0]?.lengthInches + ' inches' : '';
    const deepEndFtForDisplay = selectedJob.pools?.[0]?.deepEndFt ? selectedJob.pools?.[0]?.deepEndFt + ' ft ' : 'n/a';
    const deepEndInchesForDisplay = selectedJob.pools?.[0]?.deepEndInches ? selectedJob.pools?.[0]?.deepEndInches + ' inches' : '';
    const shallowEndFtForDisplay = selectedJob.pools?.[0]?.shallowEndFt ? selectedJob.pools?.[0]?.shallowEndFt + ' ft ' : 'n/a';
    const shallowEndInchesForDisplay = selectedJob.pools?.[0]?.shallowEndInches ? selectedJob.pools?.[0]?.shallowEndInches + ' inches' : '';
    const tanningLedgeFtForDisplay = selectedJob.pools?.[0]?.tanningLedgeFt ? selectedJob.pools?.[0]?.tanningLedgeFt + ' ft ': 'n/a';
    const tanningLedgeInchesForDisplay = selectedJob.pools?.[0]?.tanningLedgeInches ? selectedJob.pools?.[0]?.tanningLedgeInches + ' inches' : '';
    const benchFtForDisplay = selectedJob.pools?.[0]?.benchFt ? selectedJob.pools?.[0]?.benchFt + ' ft ' : 'n/a';
    const benchInchesForDisplay = selectedJob.pools?.[0]?.benchInches ? selectedJob.pools?.[0]?.benchInches + ' inches' : '';
    const newOrReplasterFirstLetter = selectedJob.pools?.[0]?.newOrReplaster?.charAt(0) || '';
    const newOrReplasterFirstLetterCapital = newOrReplasterFirstLetter?.toUpperCase();
    const newOrReplasterRestOfWord = selectedJob.pools?.[0]?.newOrReplaster?.slice(1) || '';
    const newOrRePlasterForDisplay = newOrReplasterFirstLetterCapital + newOrReplasterRestOfWord;

    const jobDetailsDataGunite = {
      "General Information": {
        "Service": selectedJob.jobType == 'gunite' ? "Gunite" : "Plaster",
        "Service Provider": selectedScheduler.location.label || "",
        "ASR Certification": selectedJob.guniteType === 'ASR Certification' ? "Yes" : 'No',
        "Requested Date": formatDate(selectedJob.requestedDate) || "",
      },
      "Builder Information": {
        "First Name": selectedJob.owner?.customer?.firstName || "",
        "Last Name": selectedJob.owner?.customer?.lastName || "",
        "Business Name": selectedJob.owner?.customer?.bizName || "",
        "Business Address": selectedJob.owner?.customer?.bizAddress || "",
        "Email": selectedJob.owner?.email || "",
        "Phone": selectedJob.owner?.customer?.phone || "",
      },
      "Job Site Information": {
        "Job Name": selectedJob.jobName || "",
        "Job Site Address": selectedJob.address || "",
        "City": selectedJob.city || "",
        "State": selectedJob.state || "",
        "Zip Code": selectedJob.zipCode || "",
        "Gate Code": selectedJob.gateCode || "",
        "Job Site Notes": selectedJob.notes,
        "Type Of Job": selectedJob.residentialOrCommercial == 'residential' ? "Residential" : "Commercial",
        "HUD Job?": selectedJob.isHudJob ? 'Yes' : 'No',
        "Electricity On Site?": selectedJob.electricityOnSite ? 'Yes' : 'No',
        "Water On Site?": selectedJob.waterOnSite ? 'Yes' : 'No'
      },
      "Pool": {
        "Pool Plan": selectedJob.pools && selectedJob.pools.length > 0 && selectedJob.pools[0].planUri != null ? (
          <span className="flex flex-row items-center justify-center ml-1.5 text-dark-blue py-1">
            <img
              src="/JobDetails/draft.svg"
              alt="check"
              className="ml-1 w-[13px]"
            />
            <p className="ml-2 text-dark-blue">{selectedJob.pools[0].planUri}</p>
          </span>
        ) : "None",
        "Square Footage": selectedJob.pools?.[0]?.squareFt ? selectedJob.pools?.[0]?.squareFt + ' sq ft' : 'n/a',
        "Perimeter": perimeterFtForDisplay + perimeterInchesForDisplay || 'n/a',
        "Depth": selectedJob.pools?.[0]?.depth ? selectedJob.pools?.[0]?.depth : 'n/a',
        "Spa": selectedJob.pools?.[0]?.spa ? "Yes" : "No",
        "Negative Edge": selectedJob.pools?.[0]?.negativeEdge ? "Yes" : "No",
        "Raised Beam": selectedJob.pools?.[0]?.raisedBeam ? "Yes" : "No",
        "Retaining Walls": selectedJob.pools?.[0]?.retainingWalls ? "Yes" : "No",
        "Notch": selectedJob.pools?.[0]?.notch ? "Yes" : "No"
      },
      "Plaster": {
        "New Pool or Re-Plaster?": newOrRePlasterForDisplay || 'n/a',
        "Material": selectedJob.pools?.[0]?.material || 'n/a',
        "Color": selectedJob.pools?.[0]?.color || 'n/a',
        "Material Add Ins": selectedJob.pools?.[0]?.materialAddIns || 'n/a',
        "Estimate Needed": (selectedJob.pools?.[0]?.estimateNeeded ? 'Yes' : 'No') || "",
        "Pool is dug out": selectedJob.pools?.[0]?.poolIsDugOut ? 'Yes' : 'No',
        "Pool plumbing is done": selectedJob.pools?.[0]?.plumbingIsDone ? 'Yes' : 'No'
      },
      "Supervisor": {
        "First Name": selectedJob.supervisorFName || "",
        "Last Name": selectedJob.supervisorLName || "",
        "Phone Number": selectedJob.supervisorPhone || "",
        "Email": selectedJob.supervisorEmail || "",
        "Notification Method": selectedJob.notificationMethod || 'n/a',
      }
    };

    const jobDetailsDataPlaster = {
      "General Information": {
        "Service": selectedJob.jobType === 'gunite' ? "Gunite" : "Plaster",
        "Service Provider": selectedScheduler.location.label || "",
        "Requested Date": formatDate(selectedJob.requestedDate) || ""
      },
      "Builder Information": {
        "First Name": selectedJob.owner?.customer?.firstName || "",
        "Last Name": selectedJob.owner?.customer?.lastName || "",
        "Business Name": selectedJob.owner?.customer?.bizName || "",
        "Business Address": selectedJob.owner?.customer?.bizAddress || "",
        "Email": selectedJob.owner?.email || "",
        "Phone": selectedJob.owner?.customer?.phone || "",
      },
      "Job Site Information": {
        "Job Name": selectedJob.jobName || "",
        "Job Site Address": selectedJob.address || "",
        "City": selectedJob.city || "",
        "State": selectedJob.state || "",
        "Zip Code": selectedJob.zipCode || "",
        "Gate Code": selectedJob.gateCode || "",
        "Job Site Notes": selectedJob.notes,
        "Type Of Job": selectedJob.residentialOrCommercial == 'residential' ? "Residential" : "Commercial",
        "HUD Job?": selectedJob.isHudJob ? 'Yes' : 'No',
        "Electricity On Site?": selectedJob.electricityOnSite ? 'Yes' : 'No',
        "Water On Site?": selectedJob.waterOnSite ? 'Yes' : 'No'
      },
      "Pool": {
        "Pool Plan": selectedJob.pools && selectedJob.pools.length > 0 && selectedJob.pools[0].planUri != 'none' ? (
          <span className="flex flex-row items-center justify-center ml-1.5 text-dark-blue py-1">
            <img
              src="/JobDetails/draft.svg"
              alt="check"
              className="ml-1 w-[13px]"
            />
            <p className="ml-2 text-dark-blue">{selectedJob.pools[0].planUri}</p>
          </span>
        ) : "None",
        "Square Footage": selectedJob.pools?.[0]?.squareFt ? selectedJob.pools?.[0]?.squareFt + ' sq ft' : 'n/a',
        "Perimeter": perimeterFtForDisplay + perimeterInchesForDisplay || 'n/a',
        "Length" : lengthFtForDisplay + lengthInchesForDisplay,
        "Width": widthFtForDisplay + widthInchesForDisplay,
        "Deep End": deepEndFtForDisplay + deepEndInchesForDisplay,
        "Shallow End": shallowEndFtForDisplay + shallowEndInchesForDisplay,
        "Tanning Ledge": tanningLedgeFtForDisplay + tanningLedgeInchesForDisplay,
        "Bench" : benchFtForDisplay + benchInchesForDisplay,
        "Steps": selectedJob.pools?.[0]?.steps || 'n/a',
        "Waterfall Pads": selectedJob.pools?.[0]?.waterfall || 'n/a',
        "Barstools": selectedJob.pools?.[0]?.barstools || 'n/a',
        "Drain Covers": selectedJob.pools?.[0]?.drainCovers || 'n/a',
        "Fittings": selectedJob.pools?.[0]?.fittings || 'n/a',
        "Basin": selectedJob.pools?.[0]?.basin ? "Yes" : "No",
        "Spa": selectedJob.pools?.[0]?.spa ? "Yes" : "No",
      },
      "Plaster": {
        "New Pool or Re-Plaster?": newOrRePlasterForDisplay || 'n/a',
        "Material": selectedJob.pools?.[0]?.material || 'n/a',
        "Color": selectedJob.pools?.[0]?.color || 'n/a',
        "Material Add Ins": selectedJob.pools?.[0]?.materialAddIns || 'n/a',
        "Estimate Needed": (selectedJob.pools?.[0]?.estimateNeeded ? 'Yes' : 'No') || "",
        "Pool Prep Needed": selectedJob.pools?.[0]?.weWillPrepPool ? 'Yes' : 'No',
      },
      "Supervisor": {
        "First Name": selectedJob.supervisorFName || "",
        "Last Name": selectedJob.supervisorLName || "",
        "Phone Number": selectedJob.supervisorPhone || "",
        "Email": selectedJob.supervisorEmail || "",
        "Notification Method": selectedJob.notificationMethod || 'n/a',
      }
    }

    return (
      <>
        {selectedJob.jobType === 'gunite' ? (
          <div>
            {Object.entries(jobDetailsDataGunite).map(([sectionTitle, sectionData]) => (
              <DataSection key={sectionTitle} title={sectionTitle} sectionData={sectionData} />
            ))}
          </div>
        ) : (
          <div>
            {Object.entries(jobDetailsDataPlaster).map(([sectionTitle, sectionData]) => (
              <DataSection key={sectionTitle} title={sectionTitle} sectionData={sectionData} />
            ))}
          </div>
        )}

      </>
    );
  };

  export default JobDetailsDetailsTabReadable;
