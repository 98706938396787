import React, { useEffect, useState, useRef } from 'react';
import { useScheduler } from '../../contexts/scheduler';
import MobileNavOptions from '../MobileNavOptions'
import MobileNavLogout from '../MobileNavLogout'
import { useResponsive } from '../../contexts/responsive';
import { useAuth } from '../../contexts/auth';
import { useAdmin } from '../../contexts/admin';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import './index.scss'
import { MdOutlineClose } from 'react-icons/md';
import SchedulerLocationDropdown from '../SchedulerLocationDropdown';
import cookies from 'browser-cookies'

const MobileNav = ({role}) => {
  const { showMobileNav, setShowMobileNav, selectedScheduler, setSelectedScheduler, locationDropdownOptions } = useScheduler()
  const { responsive } = useResponsive();
  const { setSelectedLocation, allLocations } = useAdmin();
  const { me } = useAuth();
  const [isAnimating, setIsAnimating] = useState(false);
  const [visible, setVisible] = useState(false);
  const drawerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const handleToggle = () => {
    setShowMobileNav(!showMobileNav)
  }

  useEffect(() => {
    if (showMobileNav) {
      setVisible(true);
      setIsAnimating(true);
    }
  }, [showMobileNav]);

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (!showMobileNav) {
        setVisible(false);
      }
      setIsAnimating(false);
    };

    const drawer = drawerRef.current;
    if (drawer) {
      drawer.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (drawer) {
        drawer.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [showMobileNav]);



  const style = {
    width: responsive.width - 64,
    height: '100%',
    top: 0,
    position: 'fixed',
    zIndex: 10,
  };

  const animationClass = isAnimating ? (showMobileNav ? 'drawerSlideIn' : 'drawerSlideOut') : '';

  const handleOverlayClick = (e) => {
    setTimeout(() => {
      handleToggle();
    }, 300);
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };
 
  return (
    <>
      {visible && isAnimating && (
        <div onClick={handleOverlayClick} className="z-10 fixed inset-0 flex justify-end lg:justify-end items-center">
          <div onClick={handleContentClick} ref={drawerRef} style={style} className={`z-10 border border-black flex flex-col lg:hidden text-white bg-dark-blue ${animationClass}`}>
            <div className='flex flex-row justify-between h-8 py-3 px-3'>
              <div />
              <MdOutlineClose onClick={handleToggle} className='cursor-pointer text-3xl' />
            </div>
            <div className='pt-3 pb-8 pl-6 pr-6 flex flex-col justify-between items-start'>
              <MobileNavOptions role={role}/>
            </div>
            <div className={`flex flex-col ${me.scheduler.length > 1 ? 'justify-between' : 'justify-end'} h-full pl-6`}>
              {me.scheduler.length > 1 &&
                <SchedulerLocationDropdown
                  value={selectedScheduler?.location?.id}
                  placeholder={'Schedule Location:'}
                  options={locationDropdownOptions}
                  onChange={(e) => {
                    const selectedLocationId = parseInt(e.target.value);
                    const matchingScheduler = me?.scheduler?.find((item => item.location.id === selectedLocationId));
                    if (matchingScheduler) {
                      cookies.set('schedulerId', matchingScheduler.id.toString());
                      setSelectedScheduler(matchingScheduler);
                      if (me?.role === 'SCHEDULER'){
                        handleOverlayClick()
                      }
                    }
                    const matchingLocation = allLocations?.find(item => item.id === selectedLocationId);
                    
                    if (matchingLocation) {
                      setSelectedLocation(matchingLocation)
                      if (currentUrl?.includes('admin/setup/details')){
                        if (me?.role === 'ADMIN' || me?.role === 'BRANCHADMIN'){
                          navigate(currentUrl + '?branchId=' + matchingLocation.id);
                        }
                      }
                      handleOverlayClick()
                    }
                  }}
                  role='scheduler'
                />
              }
              <div className='pb-6'>
                <MobileNavLogout />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
