import React from 'react'
import { useModal } from '../../contexts/modal'
import cookies from 'browser-cookies'

const AskIfContinueLocModal = ({ newForeman, setSelectedForeman, setIsEditing, setSelectedCalendarJob, DEFAULT_JOB }) => {
  const { modalMethods } = useModal()

  return (
    <div className='relative flex flex-col gap-6 bg-white py-10 px-16 font-roboto max-h-[300px] my-auto lg:max-h-none lg:my-0'>
      <button onClick={modalMethods.close}>
        <img src='/x_icon_black.svg' className='w-[30px] absolute right-[12px] top-[12px]' />
      </button>
      <p className='text-lg'>
        If you change locations before submitting your current job, you will lose your work.
      </p>
      <div className='flex gap-3 justify-center'>
        <button
          className='p-2 text-lg font-semibold text-dark-blue border border-[3px] rounded border-dark-blue'
          onClick={() => {
            modalMethods.close()
          }}>Cancel</button>
        <button
          className='p-2 text-lg font-semibold text-white rounded bg-dark-blue'
          onClick={() => {
            cookies.set('foremanId', newForeman.id.toString())
            setSelectedForeman(newForeman) //necessary for changing the value of the selector
            setSelectedCalendarJob(DEFAULT_JOB) //causes useEffect to run updating state in the context
            setIsEditing(false)
            modalMethods.close()
          }}>Continue</button>
      </div>
    </div>
  )
}

export default AskIfContinueLocModal
