import React, { useState, useRef } from 'react'
import _ from 'underscore'

const Input = ({ value, placeholder, onChange, isError, isPassword, numbersOnly = false, disabled = false, money = false, edit = false }) => {
  const [touched, setTouched] = useState(false)
  const [isDisabled, setIsDisabled] = useState(disabled)
  const inputRef = useRef()

  const uniqueId = `input-${_.uniqueId()}`

  if (money) numbersOnly = true

  const focusInput = () => {
    inputRef.current.focus()
  }

  const inputStyles = () => {
    if (isDisabled) {
      return (
        'peer mt-0 block w-full pr-3 py-2 focus:ring-0 rounded placeholder-transparent text-opaque-job-details-gray border-0 bg-disabled-gray'
      )

    } else if (!touched || (touched && !isError)) {
      return (
        'peer mt-0 block w-full pr-3 py-2 focus:ring-0 rounded placeholder-transparent text-dark-blue border-0'
      )
    } else {
      return 'peer mt-0 block w-full pr-3 py-2 focus:ring-0 rounded bg-tertiary-blue/5 placeholder-transparent text-base '
    }
  }


  const labelStyles = () => {
    if (!touched || (touched && !isError)) {
      return (
        'text-xs font-normal text-black/60 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-4 peer-focus:text-xs peer-focus:top-2 text-dark-blue peer-focus:text-tertiary-blue cursor-text'
      )
    } else {
      return (
        ' text-xs font-normal text-black/60 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-4 peer-focus:text-xs peer-focus:top-2 text-red-500 peer-focus:text-tertiary-blue cursor-text'
      )
    }
  }

  const limitToNumbers = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '')
    e.target.value = value
  }

  return (
    <div className='flex flex-col group relative w-full'>
      {edit ?
        <div className='w-full flex justify-between items-end pt-1'>
          <label
            htmlFor={uniqueId}
            className={labelStyles()}
            onClick={focusInput}
          >{placeholder}</label>

          <button
            className='flex justify-center items-center w-[20px] h-[20px]'
            onClick={() => {
              if (money) {
                const e = {
                  target: {
                    value: Number(inputRef.current.value).toFixed(2)
                  }
                }
                if (onChange) onChange(e)
                // inputRef.current.value = Number(inputRef.current.value).toFixed(2)
              }
              setIsDisabled(!isDisabled)
            }}
          >
            <img
              src='/JobDetails/cost_breakdown_edit_icon2.svg'
              alt='edit icon'
              className='w-[12px] h-[12px]'
            />
          </button>
        </div>
        :
        <label
          htmlFor={uniqueId}
          className={labelStyles()}
          onClick={focusInput}
        >{placeholder}</label>
      }

      <div className={`flex relative w-full  border rounded border-tertiary-blue pr-1 pl-1 ${money ? 'pl-2.5' : ''} ${isDisabled ? 'bg-disabled-gray text-opaque-job-details-gray' : 'bg-white text-dark-blue'}`}>
        <p className={`absolute left-3 top-2 text ${money ? '' : 'hidden'}`}>$</p>
        <input
          // defaultValue={money ? Number(value).toFixed(2) : value}
          value={value}
          type={isPassword ? 'password' : 'text'}
          id={uniqueId}
          placeholder={placeholder}
          onFocus={(e) => {
            if (Number(e.target.value) === 0) {
              onChange({ target: { value: '' } })
            }
          }}
          onChange={(e) => {
            e.preventDefault()
            if (numbersOnly) limitToNumbers(e)
            setTouched(false)
            if (onChange) onChange(e)
          }}
          onBlur={() => {
            setTouched(true)

            if (money) {
              const e = {
                target: {
                  value: inputRef.current.value > 0 ? Number(inputRef.current.value).toFixed(2) : 0
                }
              }
              onChange(e)
            } else if (numbersOnly) {
              const e = {
                target: {
                  value: Number(inputRef.current.value || 0)
                }
              }
              onChange(e)
            }
          }}
          className={inputStyles()}
          ref={inputRef}
          disabled={isDisabled}
        />
      </div>
    </div>
  )
}


export default Input

