import React from "react";
import DropdownCheckBox from "../DropdownCheckBox";
import SchedulerLocationDropdown from "../SchedulerLocationDropdown";
import { useSchedulerPlanner } from '../../contexts/schedulerPlanner'
import { useScheduler } from "../../contexts/scheduler";
import { useResponsive } from "../../contexts/responsive";
import { useAuth } from "../../contexts/auth";
import cookies from 'browser-cookies';

const PlannerTopBar = () => {
  const { columnDetails } = useSchedulerPlanner()
  const { me } = useAuth();
  const { selectedScheduler, setSelectedScheduler, locationDropdownOptions } = useScheduler();
  const { isMobileView } = useResponsive();

  const locationName = selectedScheduler?.location?.label

  return (
    <div className='flex justify-between items-center h-[48px] w-full min-w-[340px] p-[8px] border-b bg-gray-light text-dark-blue'>
      <p className='text-[18px] pl-[12px] font-[700] w-fit'>
        Job Planner for {locationName}
      </p>

      <div className='w-fit h-full flex pr-[12px] items-center'>
        {!isMobileView && me?.scheduler?.length > 1 &&
          <SchedulerLocationDropdown
            value={selectedScheduler?.location?.id}
            placeholder={'Schedule Location:'}
            options={locationDropdownOptions}
            onChange={(e) => {
              const selectedLocationId = parseInt(e.target.value);
              const matchingScheduler = me?.scheduler?.find((item => item.location.id === selectedLocationId));
              if (matchingScheduler) {
                cookies.set('schedulerId', matchingScheduler.id.toString());
                setSelectedScheduler(matchingScheduler);
              }
            }}
            role='scheduler'
          />
          }
        <div className='lg:ml-[12px]'>
          <DropdownCheckBox columnDetails={columnDetails} title={'Select columns'}/>
        </div>
      </div>
    </div>
  )
}

export default PlannerTopBar;
