import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { formatPhoneNumber } from '../../utils';

const styles = StyleSheet.create({
  page: {
    width: '100%',
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Helvetica',
  }
});

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

Font.registerHyphenationCallback(word => {
  // Return entire word as unique part
  return [word];
});

const CostBreakdownPDFTemplate = ({ breakdown, selectedJob, selectedScheduler, logoImage }) => {
  const isPlaster = selectedScheduler.location.service === 'PLASTER'
  const newDate = new Date();
  const foreman = []
  selectedJob?.calendarJobs?.forEach((calJob) => {
    const name = calJob.crews[0].crew.foreman.user.name
    if (!foreman.includes(name)) {
      foreman.push(name)
    }
  })

  const dueDate = new Date(newDate)
  dueDate.setDate(dueDate.getDate() + 15)

  const dateOfService = selectedJob?.calendarJobs?.map((calJob) => {
    const date = new Date(calJob.scheduledDate)
    const formattedDate = `${months[date.getUTCMonth()] || ''}, ${date.getUTCDate() || ''} ${date.getUTCFullYear() || ''}`
    return formattedDate
  })

  const invoiceTotal = breakdown.invoice.reduce((accumulator, item) => {
    return accumulator += (item.amount * item.price)
  }, 0)

  const discountPercent = Number(breakdown.discountPercent) || 0
  const discountAmount = Number((discountPercent / 100) * invoiceTotal)
  const totalRevenue = invoiceTotal - discountAmount

  const crewMembers = []
  const contractors = []
  breakdown.crew.forEach((crew) => {
    if (crew.isContractor) {
      contractors.push(crew)
    } else {
      crewMembers.push(crew)
    }
  })

  const crewTotal = crewMembers.reduce((accumulator, item) => {
    const pools = item.pools ? Number(item.pools) : 0
    const poolRate = item.poolRate ? Number(item.poolRate) : 0
    const travel = item.travel ? Number(item.travel) : 0
    const extras = item.extras ? Number(item.extras) : 0
    return accumulator += ((pools * poolRate) + travel + extras)
  }, 0)

  const contractorTotal = contractors.reduce((accumulator, item) => {
    const amount = item.contractorAmount ? Number(item.contractorAmount) : 0
    const price = item.contractorPrice ? Number(item.contractorPrice) : 0
    return accumulator += (amount * price)
  }, 0)

  const crewTotalTax = ((crewTotal + contractorTotal) + ((crewTotal + contractorTotal) * Number(breakdown.taxRate || 0)))

  const driverTotal = breakdown.drivers.reduce((accumulator, item) => {
    const pools = item.pools ? Number(item.pools) : 0
    const poolRate = item.poolRate ? Number(item.poolRate) : 0
    const travel = item.travel ? Number(item.travel) : 0
    const extras = item.extras ? Number(item.extras) : 0
    return accumulator += ((pools * poolRate) + travel + extras)
  }, 0)

  const driverTotalTax = (driverTotal + (driverTotal * Number(breakdown.taxRate || 0)))

  const laborTotal = crewTotalTax + driverTotalTax
  const laborPercent = invoiceTotal ? (laborTotal / invoiceTotal) * 100 : 0

  const materialsTotal = breakdown.material.reduce((accumulator, item) => {
    const rowTotal = isPlaster ? (item.bags * item.costBag) : (item.yards * item.costYard)
    return accumulator += (isPlaster ? rowTotal * breakdown.batches : rowTotal)
  }, 0)

  const materialsPercent = invoiceTotal ? (materialsTotal / invoiceTotal) * 100 : 0

  const fuelTotal = breakdown.fuel.reduce((accumulator, item) => {
    if (item.name === 'Compressor red') {
      const vehicleTotal = (item.yards || 0) * item.gallonsPerYard * item.costGallon
      return accumulator += vehicleTotal
    } else {
      const vehicleTotal = ((item.milesToJob * 2) * item.trips) * (item.costGallon / item.mpg)
      return accumulator += vehicleTotal
    }
  }, 0)

  const fuelPercent = invoiceTotal ? (fuelTotal / invoiceTotal) * 100 : 0

  const insurancePercent = invoiceTotal ? (breakdown.insurance / invoiceTotal) * 100 : 0

  const totalCost = laborTotal + materialsTotal + fuelTotal + Number(breakdown.insurance)
  const totalPercent = invoiceTotal ? (totalCost / invoiceTotal) * 100 : 0

  const grossProfit = totalRevenue - totalCost
  const profitPercent = invoiceTotal ? (grossProfit / invoiceTotal) * 100 : 0

  const completeDate = new Date(selectedJob.completedAt)

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Document title='Cost-Breakdown'>
      <Page size='A4' style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 10 }}>
          <Text style={{ fontSize: 16, width: '100%', textAlign: 'center', paddingBottom: 4 }}>{selectedScheduler?.location.label}</Text>

          <View style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 10 }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%', padding: 2 }}>

              <View style={{ display: 'flex', flexDirection: 'column', width: '40%'}}>
                <Text style={{ padding: 3 }}>HOME OWNER</Text>
                <Text style={{ padding: 3 }}>ADDRESS</Text>
                <Text style={{ padding: 3 }}>CITY/STATE/ZIP</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'column', width: '60%'}}>
                <Text style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto' }}>{selectedJob.jobName}</Text>
                <Text style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto' }}>{selectedJob.address}</Text>
                <Text style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto' }}>{selectedJob.city}, {selectedJob.state} {selectedJob.zipCode}</Text>
              </View>

            </View>

            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>

              <View style={{ display: 'flex', flexDirection: 'column', width: '40%'}}>
                <Text style={{ padding: 3 }}>POOL BUILDER</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'column', width: '60%'}}>
                <Text style={{ border: 1, padding: 2 }}>{selectedJob.owner.customer.bizName}</Text>
              </View>

            </View>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 10 }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '50%', padding: 2 }}>

              <View style={{ display: 'flex', flexDirection: 'column', width: '40%'}}>
                <Text style={{ padding: 3 }}>DATE OF SERVICE</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'column', width: '60%'}}>
                {dateOfService?.map((date, i) => {
                  return (
                    <Text key={`workDay${i}`} style={{ border: 1, padding: 2 }}>{date}</Text>
                  )
                })}
              </View>

            </View>

            <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>

              <View style={{ display: 'flex', flexDirection: 'column', width: '40%'}}>
                <Text style={{ padding: 3 }}>DATE OF INVOICE</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'column', width: '60%'}}>
                <Text style={{ border: 1, padding: 2 }}>{months[completeDate.getMonth()] || ''}, {completeDate.getDate() || ''} {completeDate.getFullYear() || ''}</Text>

              </View>

            </View>
          </View>

          {/* INVOICE SECTION */}
          <View style={{ width: '100%' }} wrap={false}>

            <View style={{ width: '100%', borderBottom: 1, paddingTop: 6}}>
              <Text style={{ fontSize: 10}}>INVOICE</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2 }}>

                <View style={{ width: '20%'}}>
                  <Text style={{ paddingTop: 1 }}>NAME</Text>
                </View>

                <View style={{ width: '40%'}}>
                  <Text style={{ paddingTop: 1 }}>DESCRIPTION</Text>
                </View>

                <View style={{ width: '10%'}}>
                  <Text style={{ paddingTop: 1 }}>AMOUNT</Text>
                </View>

                <View style={{ width: '15%'}}>
                  <Text style={{ paddingTop: 1 }}>PRICE</Text>
                </View>

                <View style={{ width: '15%'}}>
                  <Text style={{ paddingTop: 1 }}>TOTAL</Text>
                </View>

              </View>

              {breakdown?.invoice?.map((item, index) => (
                <View style={[{ display: 'flex', flexDirection: 'row', width: '100%' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]} key={`invoice-${index}`}>

                  <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '20%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                    <Text>{item.name}</Text>
                  </View>

                  <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '40%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                    <Text>{item.name === 'Plaster' ? `${item.manufacturer}, ${item.color}` : item.description || ''}</Text>
                  </View>

                  <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '10%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                    <Text>{item.amount || 0}</Text>
                  </View>

                  <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                    <Text>${numberWithCommas(Number(item.price).toFixed(2) || 0)}</Text>
                  </View>

                  <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                    <Text>${numberWithCommas(Number((item.price || 0) * (item.amount || 0)).toFixed(2))}</Text>
                  </View>

                </View>
              ))}

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                  <Text>INVOICE TOTAL</Text>
                </View>

                <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                  <Text>${numberWithCommas(Number(invoiceTotal || 0).toFixed(2))}</Text>
                </View>

              </View>

            </View>

          </View>

          {/* CREW + CONTRACTOR SECTION */}
          <View style={{ width: '100%' }} wrap={false}>

            <View style={{ width: '100%', borderBottom: 1}}>
              <Text style={{ fontSize: 10}}>CREW</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2 }}>

                <View style={{ width: '23%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>NAME</Text>
                </View>

                <View style={{ width: '17%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>ROLE</Text>
                </View>

                <View style={{ width: '9%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>POOLS</Text>
                </View>

                <View style={{ width: '12%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>POOL RATE</Text>
                </View>

                <View style={{ width: '12%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>TRAVEL</Text>
                </View>

                <View style={{ width: '12%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>EXTRAS</Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>TOTAL</Text>
                </View>

              </View>

              {crewMembers?.map((member, index) => (
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={`crewMember-${index}`}>
                  <View style={[{ display: 'flex', flexDirection: 'row', width: '100%' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]} >

                    <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '23%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                      <Text>{member.name || ''}</Text>
                    </View>

                    <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '17%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                      <Text>{member.role || ''}</Text>
                    </View>

                    <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '9%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                      <Text>{member.pools || ''}</Text>
                    </View>

                    <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                      <Text>${Number(member.poolRate).toFixed(2) || 0}</Text>
                    </View>

                    <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                      <Text>${Number(member.travel).toFixed(2) || 0}</Text>
                    </View>

                    <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                      <Text>${Number(member.extras || 0).toFixed(2) || 0}</Text>
                    </View>

                    <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                      <Text>${numberWithCommas(((Number(member.pools) * Number(member.poolRate)) + Number(member.travel) + Number(member.extras)).toFixed(2))}</Text>
                    </View>

                  </View>

                  {member?.extras > 0 &&
                    <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, display: 'flex', flexDirection: 'row', width: '100%', padding: 2, height: 'auto' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]}>
                      <Text>Extras reason: {member.extrasReason || ''}</Text>
                    </View>
                  }
                </View>
              ))}

              {contractors.length > 0 &&
                <>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2, paddingTop: 4 }}>
                    <View style={{ width: '31%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>CONTRACTOR NAME</Text>
                    </View>

                    <View style={{ width: '30%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>SERVICE</Text>
                    </View>

                    <View style={{ width: '12%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>AMOUNT</Text>
                    </View>

                    <View style={{ width: '12%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>PRICE</Text>
                    </View>

                    <View style={{ width: '15%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>TOTAL</Text>
                    </View>
                  </View>

                  {contractors?.map((contractor, index) => (
                    <View style={[{ display: 'flex', flexDirection: 'row', width: '100%' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]} key={`contractor-${index}`}>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '31%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{contractor.name || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '30%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{contractor.contractorService || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{contractor.contractorAmount || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(contractor.contractorPrice).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${numberWithCommas(((Number(contractor.contractorAmount) * Number(contractor.contractorPrice))).toFixed(2))}</Text>
                      </View>

                    </View>
                  ))}
                </>
              }

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                  <Text>CREW TOTAL</Text>
                </View>

                <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                  <Text>${numberWithCommas((Number(crewTotal || 0) + Number(contractorTotal || 0)).toFixed(2))}</Text>
                </View>

              </View>

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                  <Text>CREW TOTAL + TAX</Text>
                </View>

                <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                  <Text>${numberWithCommas(Number(crewTotalTax || 0).toFixed(2))}</Text>
                </View>

              </View>


            </View>

          </View>



          {/* DRIVERS SECTION */}
          {!isPlaster && breakdown?.drivers?.length > 0 &&
            <View style={{ width: '100%' }} wrap={false}>
              <View style={{ width: '100%', borderBottom: 1}}>
                <Text style={{ fontSize: 10}}>DRIVERS</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2 }}>

                  <View style={{ width: '40%', margin: 0 }}>
                    <Text style={{ paddingTop: 1 }}>NAME</Text>
                  </View>

                  <View style={{ width: '9%', margin: 0 }}>
                    <Text style={{ paddingTop: 1 }}>POOLS</Text>
                  </View>

                  <View style={{ width: '12%', margin: 0 }}>
                    <Text style={{ paddingTop: 1 }}>POOL RATE</Text>
                  </View>

                  <View style={{ width: '12%', margin: 0 }}>
                    <Text style={{ paddingTop: 1 }}>TRAVEL</Text>
                  </View>

                  <View style={{ width: '12%', margin: 0 }}>
                    <Text style={{ paddingTop: 1 }}>EXTRAS</Text>
                  </View>

                  <View style={{ width: '15%', margin: 0 }}>
                    <Text style={{ paddingTop: 1 }}>TOTAL</Text>
                  </View>

                </View>

                {breakdown?.drivers?.map((driver, index) => (
                  <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={`driver-${index}`}>
                    <View style={[{ display: 'flex', flexDirection: 'row', width: '100%' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]}>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '23%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{driver.name || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '17%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{driver.role || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '9%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{driver.pools || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(driver.poolRate).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(driver.travel).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(driver.extras || 0).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${numberWithCommas(((Number(driver.pools) * Number(driver.poolRate)) + Number(driver.travel) + Number(driver.extras)).toFixed(2))}</Text>
                      </View>

                    </View>

                    {driver?.extras > 0 &&
                      <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, display: 'flex', flexDirection: 'row', width: '100%', padding: 2, height: 'auto' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]}>
                        <Text>Extras reason: {driver.extrasReason || ''}</Text>
                      </View>
                    }
                  </View>
                ))}

                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                  <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                    <Text>DRIVERS TOTAL</Text>
                  </View>

                  <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                    <Text>${numberWithCommas(Number(driverTotal || 0).toFixed(2))}</Text>
                  </View>

                </View>

                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                  <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                    <Text>DRIVERS TOTAL + TAX</Text>
                  </View>

                  <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                    <Text>${numberWithCommas(Number(driverTotalTax || 0).toFixed(2))}</Text>
                  </View>

                </View>


              </View>
            </View>
          }


          {/* MATERIALS SECTION */}
          <View style={{ width: '100%' }} wrap={false}>

            {!isPlaster &&
              <>
                <View style={{ width: '100%', borderBottom: 1}}>
                  <Text style={{ fontSize: 10}}>MATERIALS</Text>
                </View>

                <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2 }}>

                    <View style={{ width: '25%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>NAME</Text>
                    </View>

                    <View style={{ width: '15%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>YARDS</Text>
                    </View>

                    <View style={{ width: '15%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>TONS</Text>
                    </View>

                    <View style={{ width: '15%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>COST/TON</Text>
                    </View>

                    <View style={{ width: '15%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>COST/YARD</Text>
                    </View>

                    <View style={{ width: '15%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>TOTAL</Text>
                    </View>

                  </View>

                  {breakdown?.material?.map((item, index) => (
                    <View style={[{ display: 'flex', flexDirection: 'row', width: '100%' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]} key={`material-${index}`}>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '25%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.name || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.yards || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.tons || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(item.costTon).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(item.costYard).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${numberWithCommas((Number(item.yards) * Number(item.costYard)).toFixed(2))}</Text>
                      </View>

                    </View>
                  ))}

                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                    <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                      <Text>MATERIALS TOTAL</Text>
                    </View>

                    <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                      <Text>${numberWithCommas(Number(materialsTotal || 0).toFixed(2))}</Text>
                    </View>

                  </View>

                </View>
              </>
            }

            {isPlaster &&
              <>
                <View style={{ width: '100%', borderBottom: 1}}>
                  <Text style={{ fontSize: 10}}>MATERIALS</Text>
                </View>

                <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2 }}>

                    <View style={{ width: '61%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>NAME</Text>
                    </View>

                    <View style={{ width: '12%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>BAGS</Text>
                    </View>

                    <View style={{ width: '12%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>COST/BAG</Text>
                    </View>

                    <View style={{ width: '15%', margin: 0 }}>
                      <Text style={{ paddingTop: 1 }}>TOTAL</Text>
                    </View>

                  </View>

                  {breakdown?.material?.map((item, index) => (
                    <View style={[{ display: 'flex', flexDirection: 'row', width: '100%' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]} key={`material-${index}`}>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '61%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.name || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.bags || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${numberWithCommas(Number(item.costBag).toFixed(2))}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${numberWithCommas((Number(item.bags) * Number(item.costBag)).toFixed(2))}</Text>
                      </View>

                    </View>
                  ))}

                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                    <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                      <Text>BATCHES</Text>
                    </View>

                    <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '10%', backgroundColor: '#e8e8e8', marginRight: 100 }}>
                      <Text>{breakdown?.batches}</Text>
                    </View>

                    <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                      <Text>MATERIALS TOTAL</Text>
                    </View>

                    <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                      <Text>${numberWithCommas(Number(materialsTotal || 0).toFixed(2))}</Text>
                    </View>

                  </View>

                </View>
              </>
            }

          </View>



          {/* FUEL SECTION */}
          <View style={{ width: '100%' }} wrap={false}>

            <View style={{ width: '100%', borderBottom: 1}}>
              <Text style={{ fontSize: 10}}>FUEL</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2 }}>

                <View style={{ width: '24%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>NAME</Text>
                </View>

                <View style={{ width: '8%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>MPG</Text>
                </View>

                <View style={{ width: '8%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>TRIPS</Text>
                </View>

                <View style={{ width: '18%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>{`MILES (one way)`}</Text>
                </View>

                <View style={{ width: '12%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>COST/MILE</Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>COST/GALLON</Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>TOTAL</Text>
                </View>

              </View>

              {breakdown?.fuel?.map((item, index) => (
                <View style={[{ display: 'flex', flexDirection: 'row', width: '100%' }, (index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#e8e8e8' })]} key={`fuel-${index}`}>
                  {item.name !== 'Compressor red' &&
                    <>
                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '24%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.name || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '8%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.mpg || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '8%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.trips || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '18%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.milesToJob || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '12%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(item.costMile).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${Number(item.costGallon).toFixed(2) || 0}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${numberWithCommas((((Number(item.milesToJob) * 2) * Number(item.trips)) * (Number(item.costGallon) / Number(item.mpg))).toFixed(2))}</Text>
                      </View>
                    </>
                  }

                  {item.name === 'Compressor red' &&
                    <>
                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '24%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>{item.name || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text style={{ fontSize: 8 }}>YARDS:</Text>
                        <Text>{item.yards || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '23%', display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text style={{ fontSize: 8 }}>GALLONS/YARD:</Text>
                        <Text>{item.gallonsPerYard || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '23%', display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text style={{ fontSize: 8 }}>COST/GALLON:</Text>
                        <Text>${Number(item.costGallon).toFixed(2) || ''}</Text>
                      </View>

                      <View style={[{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }, (index === 0 ? { borderTop: 1 } : {})]}>
                        <Text>${numberWithCommas((Number(item.yards) * Number(item.gallonsPerYard) * Number(item.costGallon)).toFixed(2))}</Text>
                      </View>
                    </>
                  }

                </View>
              ))}

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '22%', backgroundColor: '#e8e8e8' }}>
                  <Text>FUEL TOTAL</Text>
                </View>

                <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                  <Text>${numberWithCommas(Number(fuelTotal || 0).toFixed(2))}</Text>
                </View>

              </View>

            </View>

          </View>



          {/* SUMMARY SECTION */}
          <View style={{ width: '100%' }} wrap={false}>

            <View style={{ width: '100%', borderBottom: 1}}>
              <Text style={{ fontSize: 10}}>SUMMARY</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 8, paddingBottom: 2 }}>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}></Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>INVOICE</Text>
                </View>

                {discountPercent > 0 &&
                  <View style={{ width: '15%', margin: 0 }}>
                    <Text style={{ paddingTop: 1 }}>DISCOUNT</Text>
                  </View>
                }

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>LABOR</Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>MATERIAL</Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>FUEL</Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>INSURANCE</Text>
                </View>

                <View style={{ width: '15%', margin: 0 }}>
                  <Text style={{ paddingTop: 1 }}>GROSS PROFIT</Text>
                </View>

              </View>


              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View style={{ borderLeft: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text style={{ fontSize: 10 }}>TOTALS</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>${numberWithCommas(Number(invoiceTotal || 0).toFixed(2))}</Text>
                </View>

                {discountPercent > 0 &&
                  <View style={{ borderLeft: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                    <Text>${numberWithCommas(Number(discountAmount || 0).toFixed(2))}</Text>
                  </View>
                }

                <View style={{ borderLeft: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>${numberWithCommas(Number(laborTotal).toFixed(2))}</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>${numberWithCommas((Number(materialsTotal || 0)).toFixed(2))}</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>${numberWithCommas((Number(fuelTotal || 0)).toFixed(2))}</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>${numberWithCommas((Number(breakdown.insurance || 0)).toFixed(2))}</Text>
                </View>

                <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, borderTop: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                  <Text>${numberWithCommas(Number(grossProfit).toFixed(2))}</Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', fontWeight: 'bold' }}>PERCENT</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text></Text>
                </View>

                {discountPercent > 0 &&
                  <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                    <Text>{numberWithCommas(Number(discountPercent).toFixed(2))}%</Text>
                  </View>
                }

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>{numberWithCommas(Number(laborPercent).toFixed(2))}%</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>{numberWithCommas((Number(materialsPercent || 0)).toFixed(2))}%</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>{numberWithCommas((Number(fuelPercent || 0)).toFixed(2))}%</Text>
                </View>

                <View style={{ borderLeft: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%' }}>
                  <Text>{numberWithCommas((Number(insurancePercent || 0)).toFixed(2))}%</Text>
                </View>

                <View style={{ borderLeft: 1, borderRight: 1, borderBottom: 1, margin: 0, padding: 2, height: 'auto', width: '15%', backgroundColor: '#e8e8e8' }}>
                  <Text>{numberWithCommas(Number(profitPercent).toFixed(2))}%</Text>
                </View>
              </View>

            </View>

          </View>

        </View>
      </Page>
    </Document>
  )
}


export default CostBreakdownPDFTemplate;