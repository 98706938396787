import React from "react";
import { useAdmin } from "../../../contexts/admin";
import { useAuth } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import SchedulerLocationDropdown from "../../../components/SchedulerLocationDropdown";
import { useResponsive } from "../../../contexts/responsive";
import AdminFiles from "../../../components/AdminFiles";
import AdminBranchDetails from "../../../components/AdminBranchDetails";
import AdminBranchMaterials from "../../../components/AdminBranchMaterials";
import AdminBranchEmployees from "../../../components/AdminBranchEmployees";
import AdminBranchRates from "../../../components/AdminBranchRates";


const AdminBranchView = () => {
  const location = useLocation();
  const { me } = useAuth()
  const { isMobileView } = useResponsive();
  const { allLocations, selectedLocation, setSelectedLocation, getLocationIdFromUrl, previewImage } = useAdmin();
  const apiUrl = process.env.REACT_APP_API_URL;

  const currentUrl = location.pathname;

  const navigate = useNavigate();

  return (
    <div className="w-full overflow-y-scroll bg-opacity-10 bg-navy-bright flex flex-col justify-start items-center">
      <div className={`bg-white rounded-2xl w-full md:w-5/6 xl:w-2/3 md:mr-5 my-6 flex flex-shrink flex-col border border-navy-light`}>
        <div className="h-16 relative flex justify-between items-center px-6  rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
          <h1 className="text-xl font-semibold text-dark-blue">Branch setup</h1>
          <div className='flex'>
            <div className='flex justify-end pr-6'>
              {!isMobileView && allLocations?.length > 1 &&
                <SchedulerLocationDropdown
                  value={selectedLocation?.label || ''}
                  placeholder={'Branch Location:'}
                  options={allLocations}
                  onChange={(e) => {
                    const selectedLocationLabel = e.target.value
                    const matchingLocation = allLocations.find(item => item.label === selectedLocationLabel);
                    if (matchingLocation) {
                      setSelectedLocation(matchingLocation)
                      navigate(currentUrl + '?branchId=' + matchingLocation.id)
                    }
                  }}
                  role='scheduler'
                />
              }
            </div>
            <div className='flex items-center'>
              <div className='font-normal text-gray-blue text-sm pr-6'>
                {selectedLocation?.label || ''}
              </div>
              <div className='max-h-[36px] max-w-[36px]'>
                <img
                  src= {previewImage || `${selectedLocation?.image ? `${apiUrl}/${selectedLocation?.image}` : '/main-logo-dark.png'}`}
                  crossOrigin="anonymous"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="p-3 border-t sm:border-t-0 border-navy-light bg-opacity-20 bg-navy-bright w-full sm:w-1/5 flex flex-row sm:flex-col overflow-auto">
            <div
            className={`p-2 rounded-md cursor-pointer select-none  ${(currentUrl === '/admin/setup/details' || currentUrl === '/branchadmin/setup/details') ? 'bg-opacity-30 bg-navy-bright' : 'text-opaque-job-details-gray'}`}
            onClick={() => {
              if (me.role === 'ADMIN') navigate('/admin/setup/details?branchId=' + getLocationIdFromUrl() )
              if (me.role === "BRANCHADMIN") navigate('/branchadmin/setup/details?branchId=' + getLocationIdFromUrl())
              }}
            >
              Details
            </div>
            <div
            className={`p-2 rounded-md cursor-pointer select-none  ${(currentUrl === '/admin/setup/employees' || currentUrl === '/branchadmin/setup/employees') ? 'bg-opacity-30 bg-navy-bright' : 'text-opaque-job-details-gray'}`}
            onClick={() => {
              if (me.role === 'ADMIN') navigate('/admin/setup/employees?branchId=' + getLocationIdFromUrl() )
              if (me.role === 'BRANCHADMIN') navigate('/branchadmin/setup/employees?branchId=' + getLocationIdFromUrl() )
              }}
            >
              Employees
            </div>
            <div 
            className={`p-2 rounded-md cursor-pointer select-none  ${(currentUrl === '/admin/setup/rates' || currentUrl ===  '/branchadmin/setup/rates') ? 'bg-opacity-30 bg-navy-bright' : 'text-opaque-job-details-gray'}`}
            onClick={() => {
              if (me.role === 'ADMIN') navigate('/admin/setup/rates?branchId=' + getLocationIdFromUrl() )
              if (me.role === 'BRANCHADMIN') navigate('/branchadmin/setup/rates?branchId=' + getLocationIdFromUrl() )
              
              }}
            >
              Rates
            </div>
            <div
            className={`p-2 rounded-md cursor-pointer select-none min-inline-size-fit  ${(currentUrl === '/admin/setup/plaster-menu' || currentUrl ===  '/branchadmin/setup/plaster-menu') ? 'bg-opacity-30 bg-navy-bright' : 'text-opaque-job-details-gray'}`}
            onClick={() => {
              if (me.role === 'ADMIN') navigate('/admin/setup/plaster-menu?branchId=' + getLocationIdFromUrl() )
              if (me.role === 'BRANCHADMIN') navigate('/branchadmin/setup/plaster-menu?branchId=' + getLocationIdFromUrl() )
              
              }}
            >
              Plaster menu
            </div>
            <div
            className={`p-2 rounded-md cursor-pointer select-none  ${(currentUrl === '/admin/setup/files' || currentUrl ===  '/branchadmin/setup/files') ? 'bg-opacity-30 bg-navy-bright' : 'text-opaque-job-details-gray'}`}
            onClick={() => {
              if (me.role === 'ADMIN') navigate('/admin/setup/files?branchId=' + getLocationIdFromUrl() )
              if (me.role === 'BRANCHADMIN') navigate('/branchadmin/setup/files?branchId=' + getLocationIdFromUrl() )
              }}
            >
              Files
            </div>
          </div>
          <div className="flex flex-col w-full sm:w-4/5 py-7 px-3">
            {(currentUrl == '/admin/setup/details' || currentUrl == '/branchadmin/setup/details') &&
            <AdminBranchDetails />
            }
            {(currentUrl == '/admin/setup/employees' || currentUrl == '/branchadmin/setup/employees') &&
            <AdminBranchEmployees />
            }
            {currentUrl == '/admin/setup/crews' &&
            <div>Crews</div>
            }
            {currentUrl == '/admin/setup/poolbuilders' &&
            <div>Poolbuilders</div>
            }
            {(currentUrl == '/admin/setup/rates' || currentUrl == '/branchadmin/setup/rates') &&
            <AdminBranchRates  />
            }
            {(currentUrl == '/admin/setup/plaster-menu' || currentUrl == '/branchadmin/setup/plaster-menu') &&
            <AdminBranchMaterials />
            }
            {(currentUrl == '/admin/setup/files' || currentUrl == '/branchadmin/setup/files') &&
            <AdminFiles />
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default AdminBranchView;
