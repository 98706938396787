import React, { useState } from "react";
import { useScheduler } from "../../contexts/scheduler";
import serviceMethods from '../../service'
import { useAuth } from "../../contexts/auth";

const WordBubble = ({ word }) => (
  <div className='bg-navy-light/50 rounded-full px-2 py-1 flex justify-center items-center gap-1'>
    <p className='text-dark-blue font-medium text-xs select-none'>{word}</p>
  </div>
)

const JobDetailsDetailsCard = () => {
  const { me, setMe } = useAuth()
  const { selectedJobId, selectedScheduler } = useScheduler();
  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)
  const [isEditEstimate, setIsEditEstimate] = useState(false)
  const isPlaster = selectedScheduler.location.service === 'PLASTER'
  const [estimateValue, setEstimateValue] = useState(isPlaster ? selectedJob.estimatedIA : selectedJob.estimatedYards)

  const handleSaveEstimate = async () => {

    const id = selectedJobId
    const field = isPlaster ? 'estimatedIA' : 'estimatedYards'
    const value = Number(estimateValue)
    const updatedEstimate = await serviceMethods.updateEstimate(id, field, value)

    if (updatedEstimate.message === 'update-estimate-successful') {
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === selectedJobId)

      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                {
                  ...selectedScheduler.location.jobs[selectedJobIndex],
                  estimatedYards: updatedEstimate.updates.estimatedYards,
                  estimatedIA: updatedEstimate.updates.estimatedIA
                },
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    }


    setIsEditEstimate(false)
  }

  const limitToNumbers = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '')
    e.target.value = value
  }

  return (
    <div className='mb-8 p-4 w-[calc(100%)] lg:w-[calc(50%-12px)] rounded-xl border border-navy-light'>
      <p className='text-xl mb-2 font-bold text-secondary-blue'>Details</p>
          <div className='flex flex-col gap-3'>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/map_pin.svg' alt='map icon' />
              <p className='text-[14px] text-gray-blue'>Address:</p>
              <p className='text-[14px] font-semibold text-dark-blue'>{selectedJob.address}</p>
            </div>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/builder_icon.svg' alt='Builder icon' />
              <p className='text-[14px] text-gray-blue'>Builder:</p>
              <p className='text-[14px] font-semibold text-dark-blue'>{selectedJob.owner.customer.bizName}</p>
            </div>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/pool_size_icon.svg' alt='Pool size icon' />
              <p className='text-[14px] text-gray-blue'>Pool size:</p>
              <p className='text-[14px] font-semibold text-dark-blue'>{selectedJob.pools[0].perimeterFt} feet perimeter</p>
            </div>
              {(selectedJob.pools[0].spa || selectedJob.pools[0].negativeEdge || selectedJob.pools[0].basin) && (
                <div className='flex gap-2 items-center'>
                  <img src='/JobDetails/options_icon.svg' alt='options icon' />
                  <p className='text-[14px] text-gray-blue'>Options:</p>
                  {selectedJob.pools[0].spa && <WordBubble word='SPA' />}
                  {selectedJob.pools[0].negativeEdge && <WordBubble word='Negative Edge' />}
                  {selectedJob.pools[0].basin && <WordBubble word='Basin' />}
                </div>
              )}
              <div className='flex gap-2 items-center'>
                <img src='/JobDetails/pool_size_icon.svg' alt='Pool size icon' />
                <p
                  className={`text-[14px] text-gray-blue px-1 ${Number(estimateValue) === 0 ? 'border-b border-gunite-red' : ''}`}
                >{`Estimated ${isPlaster ? 'IA' : 'yards'}:`}</p>
                {isEditEstimate ? (
                  <div className='flex gap-2'>
                    <input
                      className='peer mt-0 block w-full pr-3 py-2 focus:ring-0 rounded placeholder-transparent text-dark-blue border w-[74px] h-7'
                      value={estimateValue}
                      onChange={(e) => {
                        limitToNumbers(e)
                        setEstimateValue(e.target.value)
                      }}
                    />
                    <button
                      className='flex justify-center items-center border p-1.5 text-dark-blue rounded h-7'
                      onClick={() => {
                        handleSaveEstimate()
                      }}
                    >Save</button>
                  </div>
                ) : (
                  <div className='flex gap-3'>
                    <p className={`text-[14px] font-semibold text-dark-blue`}>{isPlaster ? selectedJob.estimatedIA : selectedJob.estimatedYards}</p>
                    <img
                      src='/JobDetails/edit_icon.svg' alt='Edit'
                      className={`w-[18px] cursor-pointer `}
                      onClick={() => {
                        setIsEditEstimate(true)
                      }}
                    />
                  </div>
                )}
              </div>
          </div>
    </div>
  );
};

export default JobDetailsDetailsCard;
