import React from 'react';

const CustomerDraftsListItem = ({ job }) => {
  const { address } = job;

  return (
    <div className="flex gap-4 py-2 px-6 border-b border-navy-light justify-between">
      <div className="cursor-pointer text-dark-blue text-xl w-1/3">
        {job.displayName}
      </div>
      <div className='text-gray-blue w-1/3'>{address}</div>
      <div className='text-gray-blue w-1/3'>{job.jobType === 'gunite' ? "Gunite" : 'Plaster'}</div>
    </div>
  );
}

export default CustomerDraftsListItem;
