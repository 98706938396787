import React, { useState, useEffect } from "react";
import CostBreakdownSelector from "../CostBreakdownSelector";
import CostBreakdownInput from "../CostBreakdownInput";
import { useIntakeForm } from "../../contexts/intakeForm";

const CostBreakdownAddProduct = ({ isPlaster, setAddingActive, setPlasterType, add, update, deleteRow, options = {}, defaultInvoice, editRow, setEditRow }) => {
  const { materialObjects, materialDropDownList } = useIntakeForm()
  const [newItem, setNewItem] = useState(defaultInvoice)
  const [disableAdd, setDisableAdd] = useState(false)
  const [productInfo, setProductInfo] = useState({ tierList: {}, allColors: [], colorList: [] })

  const updateState = (e, field) => {
    if (field === 'name' && e.target.value === 'Plaster') {
      setNewItem(
        {
          ...newItem,
          [field]: e.target.value,
          price: options[e.target.value],
          manufacturer: '',
          minIA: 0,
          minPrice: 0,
          tier: 0,
          color: ''
        }
      )
    } else if (field === 'name') {
      setNewItem(
        {
          ...newItem,
          [field]: e.target.value,
          price: options[e.target.value],
          amount: 0
        }
      )
    } else if (field === 'manufacturer') {
      setNewItem(
        {
          ...newItem,
          [field]: e.target.value,
          tier: 0,
          color: '',
          price: 0,
          minIA: 0,
          minPrice: 0
        }
      )
      if (e.target.value !== '') {
        collectTiersAndColors(e.target.value.split(' -- '))
      } else {

        setProductInfo({
          ...productInfo,
          tierList: {}
        })
      }
    } else if (field === 'color') {
      if (e.target.value === '') return
      const newTier = calculateTierFromColor(e.target.value)
      const tierInfo = handleTierPriceInfo(newTier)
      setNewItem(
        {
          ...newItem,
          ...tierInfo,
          [field]: e.target.value,
          tier: newTier
        }
      )
    } else {
      setNewItem(
        {
          ...newItem,
          [field]: e.target.value
        }
      )
    }
  }

  // Grabs the tiers and colors of the selected manufacturer/brand
  const collectTiersAndColors = (info) => {
    if (!materialObjects) return
    const manufacturer = materialObjects.find((brand) => brand.name === info[0])
    if (!manufacturer) return
    const product = manufacturer?.product?.find((product) => product.name === info[1])
    const tierList = {}
    const colors = []
    for (const tierObj of product.tier) {
      tierList[`Tier ${tierObj.tier}`] = []
      for (const colorObj of tierObj.color) {
        colors.push(colorObj.name)
        tierList[`Tier ${tierObj.tier}`].push(colorObj.name)
      }
    }
    setProductInfo({
      ...productInfo,
      manufacturer: manufacturer.name,
      product: product.name,
      productId: product.id,
      tierObjects: product.tier,
      tierList: tierList,
      allColors: colors,
      colorList: colors,
    })

    // update plasterType here
    setPlasterType(rateChecker(product.id))
  }

  const rateChecker = (id) => {
    // Product ids
    const quartzRateIds = [3, 9, 16, 17]
    const pebbleRateIds = [1, 2, 4, 5, 6, 10, 11, 12, 13, 14, 15, 18, 19]
    if (quartzRateIds.includes(id)) {
      return 'quartz'
    }
    if (pebbleRateIds.includes(id)) {
      return 'pebble'
    }
    return 'plaster'
  }

  const calculateTierFromColor = (color) => {
    for (const tier of Object.keys(productInfo.tierList)) {
      if (productInfo.tierList[tier].includes(color)) {
        return tier.split(' ')[1]
      }
    }
  }

  const handleTierPriceInfo = (tier) => {
    const tierInfo = {
      price: 0,
      minIA: 0,
      minPrice: 0
    }
    for (const obj of productInfo.tierObjects) {
      if (obj.tier === tier) {
        tierInfo.price = obj.pricePerSqFoot
        tierInfo.minIA = 10
        tierInfo.minPrice = 20
      }
    }
    return tierInfo
  }

  const handleAdd = () => {
    setAddingActive(false)
    if (editRow.row) {
      update(newItem, editRow.i)
    } else {
      add(newItem)
    }

    setEditRow({ row: null, i: null })
  }

  useEffect(() => {
    // if editRow is not null, populate with row values
    if (editRow.row) {
      setNewItem({
        ...editRow.row
      })
    }
  }, [editRow])

  useEffect(() => {
    let disabled = false
    if (newItem.name === '' || !newItem.amount || Number(newItem.amount) === 0 || !newItem.price || Number(newItem.price) === 0) {
      disabled = true
    }
    if (newItem.name === 'Minimum') {
      if (newItem.amountForMinimum === '') {
        disabled = true
      }
      if (newItem.amount !== 1) {
        updateState({ target: { value: 1 } }, 'amount')
      }
    }
    setDisableAdd(disabled)
  }, [newItem.amount, newItem.name, newItem.price, newItem.amountForMinimum])

  useEffect(() => {
    if (newItem.manufacturer) {
      collectTiersAndColors(newItem.manufacturer.split(' -- '))
    }
  }, [newItem.manufacturer])

  return (

    <div className='flex flex-col w-full p-4 mt-4 justify-center rounded border bg-subtle-grey gap-4'>
      <div className='flex flex-col md:flex-row gap-4'>
        <div className='flex w-full md:w-1/2'>
          <CostBreakdownSelector
            value={newItem.name}
            options={Object.keys(options)}
            placeholder='Product / Service'
            onChange={(e) => {
              updateState(e, 'name')
            }}
          />
        </div>

        <div className='flex flex-col md:flex-row items-end w-full md:w-1/2 gap-4'>

          {newItem.name === 'Extras' || newItem.name === 'Minimum' ?
            <div className='w-full md:hidden'>
              <CostBreakdownInput
                value={newItem.description}
                placeholder={newItem.name === 'Minimum' ? 'Notes' : 'Description'}
                onChange={(e) => {
                  updateState(e, 'description')
                }}
              />
            </div>
            :
            ''
          }

          {newItem.name === 'Minimum' ?
            <CostBreakdownInput
              value={newItem.amountForMinimum}
              placeholder={isPlaster ? 'Total IA' : 'Total Yards'}
              numbersOnly
              onChange={(e) => {
                updateState(e, 'amountForMinimum')
              }}
            />
            :
            <CostBreakdownInput
              value={newItem.amount}
              placeholder='Amount'
              numbersOnly
              onChange={(e) => {
                updateState(e, 'amount')
              }}
            />

          }

          <CostBreakdownInput
            value={newItem.price}
            placeholder='Price'
            money
            disabled={false}
            onChange={(e) => {
              updateState(e, 'price')
            }}
          />

          {newItem.name === 'Minimum' ?
            <CostBreakdownInput
              value={Number(newItem.price) || 0}
              placeholder='Total'
              money
              disabled
            />
            :
            <CostBreakdownInput
              value={((Number(newItem.amount) || 0) * (Number(newItem.price) || 0)).toFixed(2)}
              placeholder='Total'
              money
              disabled
            />
          }

        </div>
      </div>

      {newItem.name === 'Extras' || newItem.name === 'Minimum' ?
        <div className='hidden md:flex w-full'>
          <CostBreakdownInput
            value={newItem.description}
            placeholder={newItem.name === 'Minimum' ? 'Notes' : 'Description'}
            onChange={(e) => {
              updateState(e, 'description')
            }}
          />
        </div>
        :
        ''
      }
      {newItem.name === 'Plaster' ?
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col md:flex-row w-full gap-4'>
            <div className='w-full md:w-4/6'>
              <CostBreakdownSelector
                value={newItem.manufacturer}
                options={materialDropDownList}
                placeholder='Manufacturer / Product'
                onChange={(e) => {
                  updateState(e, 'manufacturer')
                }}
              />
            </div>

            <div className='flex flex-col md:flex-row items-end gap-4 w-full md:w-2/6'>
              <CostBreakdownInput
                value={newItem.minIA}
                placeholder='Minimum total IA'
                numbersOnly
                disabled
              />
              <CostBreakdownInput
                value={newItem.minPrice}
                placeholder='Minimum total price'
                money
                disabled
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full gap-4'>

            <div className='w-full md:w-4/6'>
              <CostBreakdownSelector
                value={newItem.color}
                options={productInfo.colorList}
                placeholder='Color'
                onChange={(e) => {
                  updateState(e, 'color')
                }}
              />
            </div>

            <div className='block w-full md:w-2/6'>
              <p className='text-xs text-dark-blue pb-[2px] pt-[5px]'>Tier</p>
              <div className='w-full px-3 py-2 rounded border border-tertiary-blue text-opaque-job-details-gray bg-disabled-gray'>
                {newItem.tier}
              </div>
            </div>

          </div>
        </div>
        :
        ''
      }

      <div className='flex justify-between'>

        <div>
          <button
            className={`border border-navy-light rounded bg-white text-dark-blue py-2 px-3 font-semibold hover:bg-gunite-red hover:text-white ${editRow.row ? '' : 'hidden'}`}
            onClick={() => {
              deleteRow(editRow.i)
              setAddingActive(false)
              setEditRow({ row: null, i: null })
            }}
          >
            Delete
          </button>
        </div>

        <div className='flex justify-end gap-4'>
          <button
            className='border border-navy-light rounded bg-all-f-white py-2 px-3 font-semibold text-dark-blue hover:text-white hover:bg-gunite-red'
            onClick={() => {
              setAddingActive(false)
              setEditRow({ row: null, i: null })
            }}
          >
            Cancel
          </button>
          <button
            className={`border border-navy-light rounded py-2 px-4 font-semibold  ${disableAdd ? 'text-opaque-job-details-gray bg-disabled-gray' : 'bg-white text-dark-blue hover:bg-dark-blue hover:text-white'}`}
            disabled={disableAdd}
            onClick={() => {
              handleAdd()
            }}
          >
            {editRow.row ? 'Save' : 'Add'}
          </button>
        </div>

      </div>

    </div>
  );
};

export default CostBreakdownAddProduct;
