import React, { createContext, useContext, useState, useEffect } from 'react';
import { useScheduler } from './scheduler';
import { useAdmin } from './admin';
import {
  startOfToday,
  eachDayOfInterval,
  endOfMonth,
  startOfMonth,
  addMonths,
} from 'date-fns'
import service from '../service';

const SchedulerJobLogContext = createContext()

//barebones object of a CALENDAR JOB
const DEFAULT_JOB = {
  id: 0,
  scheduledDate: '',
  job: {
    jobName: '',
    address: '',
  },
  jobLog: {
    finishedJobPhotos: [],
    foreman: {
      user: {
        name: ''
      }
    },
    submittedAt: null
  }
}

const SchedulerJobLogProvider = props => {
  const { selectedScheduler } = useScheduler()
  const { selectedLocation } = useAdmin()
  const [todaysCalendarJobs, setTodaysCalendarJobs] = useState([DEFAULT_JOB])
  const [allCalendarJobs, setAllCalendarJobs] = useState([DEFAULT_JOB])
  const [locationCrewMembers, setLocationCrewMembers] = useState([])
  const [locationDrivers, setLocationDrivers] = useState([])
  const [locationForemen, setLocationForemen] = useState([])
  const [selectedCalendarJob, setSelectedCalendarJob] = useState(DEFAULT_JOB)
  const [selectedCrewMembers, setSelectedCrewMembers] = useState([])
  const [selectedDrivers, setSelectedDrivers] = useState([])
  const [selectedForeman, setSelectedForeman] = useState(DEFAULT_JOB.jobLog.foreman) //should be a USER object associated with a foreman
  const [deletedLocationCrewMembers, setDeletedLocationCrewMembers] = useState([])
  const [deletedLocationDrivers, setDeletedLocationDrivers] = useState([])
  const today = startOfToday() //date object
  const [currentDay, setCurrentDay] = useState(today) //date object, used for calendar navigation
  const [selectedDay, setSelectedDay] = useState(today) //date object, used for filtering jobs
  const [language, setLanguage] = useState('English')
  const [isEditing, setIsEditing] = useState(false)
  const [allPersonnel, setAllPersonnel] = useState([])
  const [personnelLocation, setPersonnelLocation] = useState(1)
  const [noAvailableForemen, setNoAvailableForemen] = useState(false);

  //future issue: find some way of filtering the full array of calendar jobs for more manageable size?

  useEffect(() => {
    const getPersonnelArray = async () => {
      const locationsArray = await service.getPersonnelForAllLocations()
      setAllPersonnel(locationsArray)
      if (selectedScheduler) {
        const selectedLocationId = Number(selectedScheduler?.location?.id);
        const matchingLocationIndex = locationsArray.findIndex(
          (item) => Number(item?.id) === selectedLocationId
        );

        const newLocationDrivers = locationsArray[matchingLocationIndex]?.drivers
          .filter((driver) => driver?.deletedAt === null);

        if (newLocationDrivers?.length){
          setLocationDrivers(newLocationDrivers);
          const newDeletedLocationDrivers = locationsArray[matchingLocationIndex]?.drivers
            .filter((driver) => driver?.deletedAt !== null);
          setDeletedLocationDrivers(newDeletedLocationDrivers);
        }

        const newLocationCrewMembers = locationsArray[matchingLocationIndex]?.crews
          .flatMap((crew) =>
            crew?.crewMembers?.filter((member) => member?.deletedAt === null)
          );

        if (newLocationCrewMembers?.length){
          setLocationCrewMembers(newLocationCrewMembers)
          const newDeletedLocationCrewMembers = locationsArray[matchingLocationIndex]?.crews
            .flatMap((crew) =>
              crew?.crewMembers?.filter((member) => member?.deletedAt !== null)
            );
          setDeletedLocationCrewMembers(newDeletedLocationCrewMembers);
        }

        const newLocationForemen = locationsArray[matchingLocationIndex]?.crews
          .filter((crew) => crew?.foreman !== null && crew?.foreman !== undefined)
          .map((crew) => crew?.foreman)
        if (newLocationForemen?.length) {
          const uniqueForemen = [];

          for (const foreman of newLocationForemen) {
            const matchingIndex = uniqueForemen.findIndex((f) => f.user.id === foreman.user.id)
            if (matchingIndex !== -1){
              uniqueForemen.splice(matchingIndex, 1);
            }
            uniqueForemen.unshift(foreman);
          }

          setLocationForemen(uniqueForemen)
          setNoAvailableForemen(false);
        } else {
          setNoAvailableForemen(true);
        }
      }
    }
    getPersonnelArray()
  }, [selectedScheduler, selectedLocation])

  useEffect(() => {
    //generate array of all calendar jobs for the location
    let newAllCalendarJobs = selectedScheduler.location.jobs.map((job) => {
      return job.calendarJobs.map((calendarJob) => calendarJob)
    })
    if (newAllCalendarJobs.length) {
      setAllCalendarJobs(newAllCalendarJobs.flat())
    } else {
      setAllCalendarJobs([DEFAULT_JOB])
    }

    //set selectedLocation to the id of the selectedScheduler to trigger the selectedLocation useEffect, which will set the crewMembers and Drivers arrays.
    setPersonnelLocation(selectedScheduler.location.id)
    //intention is to only run this code on page load to set the locationCrewMembers, locationDrivers, and locationForemen.  Once the locationCrewMembers array is populated, this will not run anymore
    if (!locationCrewMembers.length) {
      const newLocationCrewMembers = selectedScheduler.location.crews
        .flatMap((crew) =>
          crew?.crewMembers.filter((member) => member.deletedAt === null)
        )
      if (newLocationCrewMembers.length) {
        setLocationCrewMembers(newLocationCrewMembers);
        const newDeletedLocationCrewMembers = selectedScheduler.location.crews
          .flatMap((crew) =>
            crew?.crewMembers.filter((member) => member.deletedAt !== null)
          )
        setDeletedLocationCrewMembers(newDeletedLocationCrewMembers);
      }


      //generate array of all drivers for location
      setLocationDrivers(selectedScheduler.location.drivers.filter((driver) => driver.deletedAt === null))
      setDeletedLocationDrivers(selectedScheduler.location.drivers.filter((driver) => driver.deletedAt !== null))

      //generate array of all foremen for location
      const newLocationForemen = selectedScheduler.location.crews
        .filter((crew) => crew.foreman !== null && crew.foreman !== undefined)
        .map((crew) => crew.foreman)

      if (newLocationForemen.length) {
        const uniqueForemen = [];

        for (const foreman of newLocationForemen){
          const matchingIndex = uniqueForemen.findIndex((f) => f.user.id === foreman.user.id)
          if (matchingIndex !== -1){
            uniqueForemen.splice(matchingIndex, 1);
          }
          uniqueForemen.unshift(foreman)
        }

        setLocationForemen(uniqueForemen)
        setNoAvailableForemen(false);
      } else {
        setNoAvailableForemen(true);
      }
    }
  }, [selectedScheduler])

  const updateLocationPersonnel = (personnelLocation) => {
    setPersonnelLocation(personnelLocation)
    const updatedLocationDrivers = allPersonnel.find((location) => location.id === personnelLocation).drivers
      .filter((driver) => driver.deletedAt === null)
    setLocationDrivers(updatedLocationDrivers);

    const updatedDeletedLocationDrivers = allPersonnel.find((location) => location.id === personnelLocation).drivers
      .filter((driver) => driver.deletedAt !== null)
    setDeletedLocationDrivers(updatedDeletedLocationDrivers)

    const updatedLocationCrewMembers = allPersonnel.find((location) => location.id === personnelLocation).crews
      .flatMap((crew) =>
      crew?.crewMembers?.filter((member) => member?.deletedAt === null)
    );
    setLocationCrewMembers(updatedLocationCrewMembers)

    const updatedDeletedLocationCrewMembers = allPersonnel.find((location) => location.id === personnelLocation).crews
      .flatMap((crew) =>
      crew?.crewMembers?.filter((member) => member?.deletedAt !== null)
    );
    setDeletedLocationCrewMembers(updatedDeletedLocationCrewMembers);
  }

  //runs when me updates
  useEffect(() => {
    const newTodaysJobs = allCalendarJobs.filter((calendarJob) => {
      const scheduledDate = new Date(calendarJob.scheduledDate)
      return (
        scheduledDate.getUTCFullYear() === selectedDay.getFullYear()
        && scheduledDate.getUTCMonth() === selectedDay.getMonth()
        && scheduledDate.getUTCDate() === selectedDay.getDate()
      )
    })
    //perform the sort here so as to reduce the size of the sort
    //this first function places regular jobs before flash jobs
    const todaysJobsSorted = newTodaysJobs.toSorted((a, b) => {
      if (a.shift && b.shift) {
        return a.shift - b.shift
      } else if (a.fshift && b.fshift) {
        return a.fshift - b.fshift
      } else {
        if (a.fshift) return 1
        else return -1
      }
    })
    if (newTodaysJobs.length) {
      setTodaysCalendarJobs(todaysJobsSorted)
    } else {
      setTodaysCalendarJobs([DEFAULT_JOB])
      setSelectedCalendarJob(DEFAULT_JOB)
    }
  }, [allCalendarJobs])

  //runs when a new day is selected from the calendar.  Does not automatically reset the selected Calendar Job
  useEffect(() => {
    const newTodaysJobs = allCalendarJobs.filter((calendarJob) => {
      const scheduledDate = new Date(calendarJob.scheduledDate)
      return (
        scheduledDate.getUTCFullYear() === selectedDay.getFullYear()
        && scheduledDate.getUTCMonth() === selectedDay.getMonth()
        && scheduledDate.getUTCDate() === selectedDay.getDate()
      )
    })
    if (newTodaysJobs.length) {
      setTodaysCalendarJobs(newTodaysJobs)
    } else {
      setTodaysCalendarJobs([DEFAULT_JOB])
    }
    setSelectedCalendarJob(DEFAULT_JOB)
    setIsEditing(false)
  }, [selectedDay])

  // MiniCalendar date functions

  const setDays = () => {
    return eachDayOfInterval({
      start: startOfMonth(currentDay),
      end: endOfMonth(currentDay)
    })
  }

  let days = setDays() //array of date objects running through the current month

  const previousMonth = () => {
    setCurrentDay(addMonths(currentDay, -1))
  }

  const nextMonth = () => {
    setCurrentDay(addMonths(currentDay, 1))
  }

  return (
    <SchedulerJobLogContext.Provider value={{
      days,
      previousMonth, nextMonth,
      selectedDay, setSelectedDay,
      todaysCalendarJobs, setTodaysCalendarJobs,
      selectedCalendarJob, setSelectedCalendarJob,
      locationCrewMembers, setLocationCrewMembers,
      selectedCrewMembers, setSelectedCrewMembers,
      locationDrivers, setLocationDrivers,
      selectedDrivers, setSelectedDrivers,
      locationForemen, setLocationForemen,
      selectedForeman, setSelectedForeman,
      language, setLanguage,
      isEditing, setIsEditing,
      allPersonnel, updateLocationPersonnel,
      personnelLocation, setPersonnelLocation,
      deletedLocationCrewMembers, setDeletedLocationCrewMembers,
      deletedLocationDrivers, setDeletedLocationDrivers,
      noAvailableForemen, setNoAvailableForemen
    }} >
      {props.children}
    </SchedulerJobLogContext.Provider>
  )
}

const useSchedulerJobLog = () => useContext(SchedulerJobLogContext)
export { useSchedulerJobLog }
export default SchedulerJobLogProvider
